import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// Data persisting with saving and loading from local storage
import { save, load } from "redux-localstorage-simple"
import rootReducer from './reducers';
import { loyaltyMDL } from './middleware/app-middleware'

let composeEnhancers = compose;
  if (process.env.NODE_ENV !== 'production') {  
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

//const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
    rootReducer,    
    load({namespace: 'telco_web'}),
    composeEnhancers(
        applyMiddleware(
            thunk, 
            ...loyaltyMDL, 
            save({namespace: 'telco_web'})
        )
    )
);

export default store;
