import Axios from "axios";
import { apiError } from '../store/actions/interceptor-actions'
import { LOADING_START, LOADING_SUCCESS } from "../store/action-types";

const Interceptor = (store) => {
    
    Axios.interceptors.request.use(
        (request) => {
            store.dispatch({ type: LOADING_START })
            return Promise.resolve(request);
        }
    );

    Axios.interceptors.response.use(
        (next) => {
            store.dispatch({ type: LOADING_SUCCESS })
            return Promise.resolve(next);
        },
        (error) => {
            // You can handle error here and trigger warning message without get in the code inside
            store.dispatch(apiError(error.response.data));
            return Promise.reject(error);
        }
    );

};
export default {
    interceptor: Interceptor,
};
