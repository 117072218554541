import moment from 'moment';
import * as actionTypes from "../action-types";
import * as api from "../../api";
import { v4 as uuidv4 } from "uuid";
import { map, union, filter, has, forEach } from 'lodash'
import { toBoolean, toYesNo } from '../StoreHelpers';
import { getInitialAppState } from "../StoreHelpers"

export const portalFlow =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        switch (action.type) {
            case actionTypes.ON_SUBMIT_ADDRESS:
                
                let cAddress = getState().appReducer.customerAddress;
                if(!getState().appReducer.quoteNumber) {
                    dispatch({ type: actionTypes.ON_PROCEED_RESET }); 
                    
                    dispatch({
                        type: actionTypes.GENERATE_UUID,
                        payload: uuidv4(),
                    });
                }

                dispatch({ 
                    type: actionTypes.PERSIST_ADRESS_SEARCH_STATE, 
                    payload: cAddress 
                }); 

                // Upon Starting the Portal, generate UUID for each transaction
                dispatch({
                    type: actionTypes.SET_SPLASH_WAIT,
                    payload: {
                        show: true
                    },
                });

                if(!action.payload?.resume) {
                    dispatch({
                        type: actionTypes.ON_CHANGE_SITE_ADDRESS,
                        payload: action.payload,
                    });
                }
                
                dispatch({ type: actionTypes.API_ADDRESS_QUALIFICATION_START });

                let submittedAddress = '';
                if(action.payload?.resume) {
                    
                    dispatch({ type: actionTypes.TELCO_RESUME_DONE });
                    
                    submittedAddress = action.payload.address;
                } else {
                    submittedAddress = action.payload.AddressFull;
                }

                let addressSearchAPI = ''
                let addressSearchParams = {}
                if(getState().appReducer.customerAddress.searchMode === 'auto') {
                    addressSearchAPI = `/customer/address`
                    addressSearchParams = {
                        address: submittedAddress,
                        quoteNumber: getState().appReducer.quoteNumber,
                    }
                } else {
                    addressSearchAPI = `/customer/address-raw`
                    addressSearchParams = {
                        quoteNumber: getState().appReducer.quoteNumber,
                        localityName: action.payload.Suburb,
                        postcode: action.payload.Postcode,
                        roadName: action.payload.Street,
                        roadNumber1: action.payload.Number,
                        roadTypeCode: action.payload.StreetType,
                        stateTerritoryCode: action.payload.State,
                        unitNumber: action.payload.UnitNumber,
                        unitTypeCode: action.payload.UnitType
                    }
                }

                api.put(addressSearchAPI, {
                    ...addressSearchParams
                })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.SET_SPLASH_WAIT,
                            payload: {
                                show: false
                            },
                        });
                        dispatch({
                            type: actionTypes.API_ADDRESS_QUALIFICATION_SUCCESS,
                            payload: response.data,
                        });
                        if(response.data.serviceable===true) {
                            dispatch({ type: actionTypes.GET_LEAD_TIME })
                        }
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_ADDRESS_QUALIFICATION_FAIL });
                    });
                break;
            
            case actionTypes.ON_SUBMIT_ACCOUNT_DETAILS:    
                
                dispatch({ type: actionTypes.SET_ACCOUNT_DETAILS, payload: action.payload });
                
                let apiPayload = {
                    ...action.payload,
                    lifeSupport: toBoolean(action.payload.lifeSupport),
                    secureAlarm: toBoolean(action.payload.secureAlarm),
                }

                // console.log('apiPayload', apiPayload);

                dispatch({ type: actionTypes.API_ACCOUNT_DETAILS_START });

                api.put(`/customer/account-details`, {
                    ...apiPayload,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_ACCOUNT_DETAILS_SUCCESS,
                            payload: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_ACCOUNT_DETAILS_FAIL });
                    });
                
                break;
                
                case actionTypes.GET_TELCO_DETAILS:
                    dispatch({ type: actionTypes.API_TELCO_DETAILS_START });
                    
                    api.get(`/customer/telco-plans`, {
                        params: {
                            quoteNumber: getState().appReducer.quoteNumber
                        }
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_TELCO_DETAILS_SUCCESS,
                            payload: response.data
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_TELCO_DETAILS_FAIL });
                    });
                break;

                case actionTypes.ON_SELECT_PRODUCT:
                    dispatch({ type: actionTypes.API_SUBMIT_PRODUCT_START });
                    
                    api.put(`/customer/plan`, {
                        campaign: "website",
                        plan: action.payload,
                        sumoAgentName: null,
                        quoteNumber: getState().appReducer.quoteNumber
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_SUBMIT_PRODUCT_SUCCESS
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_SUBMIT_PRODUCT_FAIL });
                    });
                break;
                
                case actionTypes.ON_SUBMIT_SERVICE_INFO:
                    dispatch({ type: actionTypes.API_SERVICE_INFO_START });
                    
                    let commodityTypes = []

                    if(getState().appReducer.serviceInfo.input.existingCommodityTypes.electricity) commodityTypes.push('Electricity');
                    if(getState().appReducer.serviceInfo.input.existingCommodityTypes.gas) commodityTypes.push('Gas');
                    if(getState().appReducer.serviceInfo.input.existingCommodityTypes.internet) commodityTypes.push('Internet');

                    api.put(`/customer/service-information`, {
                        ...action.payload,
                        campaign: 'website',
                        existingSumoCustomer: toBoolean(action.payload.existingSumoCustomer),
                        existingCommodityTypes: commodityTypes,
                        quoteNumber: getState().appReducer.quoteNumber
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_SERVICE_INFO_SUCCESS
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_SERVICE_INFO_FAIL });
                    });
                break;
                
                case actionTypes.ON_SUBMIT_SERVICE_ADDONS:
                    dispatch({ type: actionTypes.API_SERVICE_ADDONS_START });
                    let x = getState().appReducer.serviceAddons.input.modemRequired;
                    let modemRequired = x ==='yes' ? true: x==='no' ? false:null;

                    let pr = getState().appReducer.serviceAddons.input.phoneRequired;
                    let phoneRequired = pr ==='yes' ? true: pr==='no' ? false:null;
                    
                    let nr = getState().appReducer.serviceAddons.input.newNumberRequired;
                    let newNumberRequired = nr ==='yes' ? true: nr==='no' ? false:null;
                    
                    let ac = getState().appReducer.serviceAddons.input.activeConnection;
                    let activeConnection = ac ==='yes' ? true: ac==='no' ? false:null;
                    
                    let ach = getState().appReducer.serviceAddons.input.authorizeAccHolder;
                    let authorizeAccHolder = ach ==='yes' ? true: ach==='no' ? false:null;
                    
                    api.put(`/customer/service-addons`, {
                        ...action.payload,
                        modemRequired: modemRequired,
                        phoneRequired: phoneRequired,
                        activeConnection: activeConnection,
                        authorizeAccHolder: authorizeAccHolder,
                        newNumberRequired: newNumberRequired,
                        modemType: 'modem1',
                        quoteNumber: getState().appReducer.quoteNumber
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_SERVICE_ADDONS_SUCCESS
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_SERVICE_ADDONS_FAIL });
                    });
                break;
                
                case actionTypes.ON_SUBMIT_CONFIRMED_CUSTOMER_DETAILS:
    
                    console.log('ON_SUBMIT_CONFIRMED_CUSTOMER_DETAILS', action.payload);
                    dispatch({ type: actionTypes.API_CONFIRMED_CUSTOMER_DETAILS_START });
                    let y = getState().appReducer.confirmedCustomerDetails.input.secondAccount;
                    let secondAccount = y ==='yes' ? true: y==='no' ? false:null;

                    api.put(`/customer/confirm-customer-details`, {
                        ...action.payload,
                        secondAccount: secondAccount,   
                        dateOfBirth: action.payload.xDateOfBirth,
                        secondDateOfBirth: action.payload.xSecondDateOfBirth,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_CONFIRMED_CUSTOMER_DETAILS_SUCCESS,
                            payload: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_CONFIRMED_CUSTOMER_DETAILS_FAIL });
                    });
                    break;
                
                case actionTypes.ON_SUBMIT_ADDRESS_DETAILS:
    
                    let siteSameAsPostal = action.payload.siteSameAsPostal==='no' ? false : true;
                    dispatch({ type: actionTypes.API_ADDRESS_DETAILS_START });
    
                    api.put(`/customer/site-details`, {
                        ...getState().appReducer.confirmedAddressDetails.input,
                        ...action.payload,
                        siteSameAsPostal: siteSameAsPostal,
                        invoice: 'email',
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_ADDRESS_DETAILS_SUCCESS,
                            payload: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_ADDRESS_DETAILS_FAIL });
                    });
                    break;
                    
                case actionTypes.ON_SUBMIT_CONSENT:
                    dispatch({
                        type: actionTypes.SET_SPLASH_WAIT,
                        payload: {
                            show: true
                        },
                    });
                    
                    dispatch({ type: actionTypes.API_CONSENT_START });
    
                    api.put(`/customer/consents-and-agreement`, {
                        ...action.payload,
                        consentAndAgreementForEic: getState().appReducer.consentAgreement.input.consentAndAgreementForEic,
                        contactByPhoneOrEmail: getState().appReducer.consentAgreement.input.contactByPhoneOrEmail,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => { 
                        dispatch({ type: actionTypes.API_CONSENT_SUCCESS });

                        dispatch({ type: actionTypes.API_SUBMIT_SIGNUP_START });

                        api.put(`/customer/signup?quoteNumber=${getState().appReducer.quoteNumber}`)
                        .then((response) => {
                            if(response.data.success === true){
                                dispatch({
                                    type: actionTypes.API_SUBMIT_SIGNUP_SUCCESS,
                                    payload: response.data,
                                });
                                dispatch({
                                    type: actionTypes.SET_SPLASH_WAIT,
                                    payload: {
                                        show: false
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            dispatch({ type: actionTypes.API_SUBMIT_SIGNUP_FAIL });
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_CONSENT_FAIL });
                    });
                    break;

                case actionTypes.GET_LEAD_TIME:
    
                    dispatch({ type: actionTypes.API_LEAD_TIME_START });
    
                    api.get(`/customer/lead-time`, {
                        params: {
                            quoteNumber: getState().appReducer.quoteNumber,
                        }
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_LEAD_TIME_SUCCESS,
                            payload: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_LEAD_TIME_FAIL });
                    });
                    break;

                case actionTypes.INIT_COSTS:
    
                    dispatch({ type: actionTypes.API_COSTS_START });
    
                    api.get(`/customer/prices-calculation`, {
                        params: {
                            quoteNumber: getState().appReducer.quoteNumber,
                        }
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_COSTS_SUCCESS,
                            payload: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_COSTS_FAIL });
                    });
                    break;

                case actionTypes.ON_SUBMIT_PAYMENT:

                    dispatch({
                        type: actionTypes.SET_SPLASH_WAIT,
                        payload: {
                            show: true
                        },
                    });

                    let paymentPayload = {};
                    let cardDetails = {};
                    const expiry = action.payload['creditCardExpiry'].split('/');

                    if(getState().appReducer.summaryPayment.input.paymentMethod==='CREDIT_CARD') {
                        cardDetails['creditCardNumber'] = action.payload['creditCardNumber'];
                        cardDetails['creditCardName'] = action.payload['creditCardName'];
                        cardDetails['creditCardSecurityCode'] = parseInt(action.payload['creditCardSecurityCode']);
                        cardDetails['ccExpiryMonth'] = parseInt(expiry[0]);
                        cardDetails['ccExpiryYear'] = parseInt(expiry[1]);

                        paymentPayload = {
                            creditCardDto: cardDetails
                        }
                    } else if(getState().appReducer.summaryPayment.input.paymentMethod==='BANK_ACCOUNT') {
                        cardDetails['customerName'] = action.payload['customerName'];
                        cardDetails['bsb'] = action.payload['bsb'];
                        cardDetails['accountNumber'] = action.payload['accountNumber'];
                        
                        paymentPayload = {
                            bankAccountDto: cardDetails
                        }
                    }
                    dispatch({ type: actionTypes.API_PAYMENT_START });
    
                    api.put(`/customer/payment-details`, {
                        paymentMethod: getState().appReducer.summaryPayment.input.paymentMethod,
                        ...paymentPayload,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        console.log('responsable', response);
                        if(response.data.success === true){
                        
                            dispatch({
                                type: actionTypes.API_PAYMENT_SUCCESS,
                                payload: response.data,
                            });
                        } else {
                            dispatch({ type: actionTypes.API_PAYMENT_FAIL });
                        }
                        dispatch({
                            type: actionTypes.SET_SPLASH_WAIT,
                            payload: {
                                show: false
                            },
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_PAYMENT_FAIL });
                    });
                    break;
    
                case actionTypes.INIT_TELCO_RESUME:
                    dispatch({ type: actionTypes.API_TELCO_RESUME_START });
    
                    api.get(`/customer/retrieval-customer-details`, {
                        params: {
                            quoteNumber: action.payload,
                        }
                    })
                    .then((response) => {
                        
                        let resumeState = getInitialAppState();
                        
                        resumeState.quoteNumber = action.payload;
                        resumeState.resume = {
                            status: 'success'
                        }

                        forEach(response.data, function(field) {
                            
                            // resumeState.resume[field.label] = field.value;

                            switch (field.label) {
                                case 'address':
                                    resumeState.customerAddress.input = field.value;
                                    resumeState.confirmedAddressDetails.input.siteAddress = field.value;
                                    resumeState.resume.address = field.value;
                                    break;
                                
                                case 'serviceType':
                                    
                                    break;
                                
                                case 'newNumberRequired':
                                    resumeState.serviceAddons.input.newNumberRequired = toYesNo(field.value);
                                    break;
                                
                                case 'authenticationName':
                                    resumeState.authenticationId.input.authenticationName = field.value;
                                    break;

    //--------------------------------------------------------
                                case 'customerTitle':
                                    resumeState.confirmedCustomerDetails.input.customerTitle = field.value;
                                    break;

                                case 'customerFirstName':
                                    resumeState.confirmedCustomerDetails.input.customerFirstName = field.value;
                                    break;

                                case 'customerLastName':
                                    resumeState.confirmedCustomerDetails.input.customerLastName = field.value;
                                    break;

                                case 'dateOfBirth':
                                    resumeState.confirmedCustomerDetails.input.dateOfBirth = field.value;
                                    break;

                                case 'email':
                                    resumeState.confirmedCustomerDetails.input.email = field.value;
                                    break;

                                case 'mobileNumber':
                                    resumeState.confirmedCustomerDetails.input.mobileNumber = field.value;
                                    break;

                                case 'secondAccount':
                                    resumeState.confirmedCustomerDetails.input.secondAccount = toYesNo(field.value);
                                    break;

                                case 'secondCustomerTitle':
                                    resumeState.confirmedCustomerDetails.input.secondCustomerTitle = field.value;
                                    break;

                                case 'secondCustomerFirstName':
                                    resumeState.confirmedCustomerDetails.input.secondCustomerFirstName = field.value;
                                    break;

                                case 'secondCustomerLastName':
                                    resumeState.confirmedCustomerDetails.input.secondCustomerLastName = field.value;
                                    break;

                                case 'secondDateOfBirth':
                                    resumeState.confirmedCustomerDetails.input.secondDateOfBirth = field.value;
                                    break;

                                case 'secondMobileNumber':
                                    resumeState.confirmedCustomerDetails.input.secondMobileNumber = field.value;
                                    break;

                                case 'secondEmail':
                                    resumeState.confirmedCustomerDetails.input.secondEmail = field.value;
                                    break;
    //--------------------------------------------------------
                                case 'siteState':
                                    resumeState.confirmedAddressDetails.input.siteState = field.value;
                                    break;

                                case 'postalAddress':
                                    resumeState.confirmedAddressDetails.input.postalAddress = field.value;
                                    break;

                                case 'siteSameAsPostal':
                                    resumeState.confirmedAddressDetails.input.siteSameAsPostal = toYesNo(field.value);
                                    break;

                                case 'proposedMovingDate':
                                    resumeState.confirmedAddressDetails.input.proposedMovingDate = field.value;
                                    break;

                                case 'siteHouseNumber':
                                    resumeState.confirmedAddressDetails.input.siteHouseNumber = field.value;
                                    break;

                                case 'sitePostcode':
                                    resumeState.confirmedAddressDetails.input.sitePostcode = field.value;
                                    break;

                                case 'siteStreetName':
                                    resumeState.confirmedAddressDetails.input.siteStreetName = field.value;
                                    break;

                                case 'siteStreetType':
                                    resumeState.confirmedAddressDetails.input.siteStreetType = field.value;
                                    break;

                                case 'siteSuburb':
                                    resumeState.confirmedAddressDetails.input.siteSuburb = field.value;
                                    break;

                                case 'siteUnitNumber':
                                    resumeState.confirmedAddressDetails.input.siteUnitNumber = field.value;
                                    break;

                                case 'siteUnitNumberSuffix':
                                    resumeState.confirmedAddressDetails.input.siteUnitNumberSuffix = field.value;
                                    break;

                                case 'siteUnitType':
                                    resumeState.confirmedAddressDetails.input.siteUnitType = field.value;
                                    break;

                                case 'modemRequired':
                                    resumeState.confirmedAddressDetails.input.modemRequired = field.value;
                                    break;
//-------------------------------------------------------
                                case 'aaaaaaaaaaa':
                                    resumeState.confirmedAddressDetails.input.aaaaaaaaaaa = field.value;
                                    break;

                                default:
                                    break;
                            }
                        });
                        console.log('resumeState', resumeState);
                        
                        dispatch({
                            type: actionTypes.API_TELCO_RESUME_SUCCESS,
                            payload: resumeState
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_TELCO_RESUME_FAIL });
                    });
                    break;
//===========================================================

                case actionTypes.START_PORTAL:
                // Upon Starting the Portal, generate UUID for each transaction
                dispatch({
                    type: actionTypes.GENERATE_UUID,
                    payload: uuidv4(),
                });
                dispatch({ type: actionTypes.API_QUOTE_REFERENCE_START });

                api.get(`/customer/initialize-order`, {
                    params: {
                        quoteNumber: getState().appReducer.quoteNumber,
                    },
                })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_QUOTE_REFERENCE_SUCCESS,
                            payload: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_QUOTE_REFERENCE_FAIL });
                    });
                break;

            case actionTypes.ON_SEARCH_CUSTOMER_ACCOUNT:
                dispatch({ 
                    type: actionTypes.API_CUSTOMER_ACCOUNT_START, 
                    payload: {
                        accountNumber: action.payload,
                        found: null
                    }
                });

                api.get(`/customer/details`, {
                    params: {
                        accountNumber: action.payload,
                        quoteNumber: getState().appReducer.quoteNumber,
                    },
                })
                .then((response) => {
                    
                    let data = {
                        ...response.data,
                        xDateOfBirth: moment(response.data.dateOfBirth).format("ll"),
                        xContractStartDate: moment(response.data.contractStartDate).format("ll"),
                        xContractEndDate: moment(response.data.contractEndDate).format("ll")
                    };

                    dispatch({
                        type: actionTypes.API_CUSTOMER_ACCOUNT_SUCCESS,
                        payload: {
                            customerAccount: { ...data, found: true } 
                        }
                    });
                    
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_CUSTOMER_ACCOUNT_FAIL, payload: error });
                });
                break;

            case actionTypes.SELECT_CAMPAIGN:
                dispatch({
                    type: actionTypes.SET_SELECTED_CAMPAIGN,
                    payload: {
                        campaign: {
                            data: action.payload,
                            status: 'loading'
                        }
                    }
                });

                dispatch({ type: actionTypes.API_CAMPAIGN_START });
                api.put(`/customer/campaign`, {
                    campaign: action.payload,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_CAMPAIGN_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ 
                        type: actionTypes.API_CAMPAIGN_FAIL, 
                        payload: {
                            campaign: {
                                status: 'failed'
                            }
                        }
                    });
                });
                break;
            case actionTypes.SELECT_DECISION:
                dispatch({ type: actionTypes.API_DECISION_START });

                // Dispatch this immediately for UI/UX purposes
                dispatch({
                    type: actionTypes.SET_DECISION,
                    payload: {
                        decision: {
                            data: action.payload,
                            status: 'loading',
                            showDecisionSplash: action.payload
                        }
                    },
                });
                
                switch (
                    action.payload //Decision [winback, cancellation, retention]
                ) {
                    case "winback":
                        dispatch({
                            type: actionTypes.SET_WINBACK_STEPS,
                            payload: "winback",
                        });
                        break;

                    case "cancellationretention":
                        dispatch({
                            type: actionTypes.SET_CANCELLATION_RETENTION_STEPS,
                            payload: "cancellationretention",
                        });
                        break;

                    default:
                        break;
                }

                api.put(`/customer/decision`, {
                    campaign: action.payload,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_DECISION_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ 
                        type: actionTypes.API_DECISION_FAIL, 
                        payload: {
                            status: 'failed'
                        }
                    });
                });
                break;
            case actionTypes.ON_SELECT_INTERACTION_TYPE:
                dispatch({ type: actionTypes.API_INTERACTION_TYPE_START });

                api.put(`/customer/cancellation-retention/interaction-type`, {
                    interactionType: action.payload,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_INTERACTION_TYPE_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_INTERACTION_TYPE_FAIL, status: 'failed' });
                });
                break;

            case actionTypes.ON_SUBMIT_CHURN_INFO:
                dispatch({ type: actionTypes.API_CHURN_INFO_START });

                api.put(`/customer/cancellation-retention/churn-information`, {
                    ...action.payload,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_CHURN_INFO_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_CHURN_INFO_FAIL, status: 'failed' });
                });
                break;
            
                case actionTypes.SET_CANCELLATION_REASON:
                dispatch({ type: actionTypes.API_CANCELLATION_REASON_START });

                api.put(`/customer/cancellation/cancellation-reason`, {
                    ...action.payload,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({
                        type: actionTypes.API_CANCELLATION_REASON_SUCCESS,
                        payload: response.data,
                    });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_CANCELLATION_REASON_FAIL });
                });
                break;
            case actionTypes.ON_SELECT_COMMODITY_TYPE:
                dispatch({
                    type: actionTypes.SET_COMMODITY_TYPE,
                    payload: {
                        commodityType: {
                            data: action.payload,
                            status: 'loading'
                        }
                    }
                });

                break;
            case actionTypes.SUBMIT_COMMODITY_TYPE:
                dispatch({ type: actionTypes.API_COMMODITY_TYPE_START });

                api.put(`/customer/winback/commodity-type`, {
                    ...action.payload,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_COMMODITY_TYPE_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_COMMODITY_TYPE_FAIL, status: 'failed' });
                });
                break;
            case actionTypes.ON_SELECT_CUSTOMER_TYPE:
                dispatch({
                    type: actionTypes.SET_CUSTOMER_TYPE,
                    payload: {
                        customerType: {
                            data: action.payload,
                            status: 'loading'
                        }
                    }
                });
                
                dispatch({ type: actionTypes.API_CUSTOMER_TYPE_START });

                api.put(`/customer/winback/customer-type`, {
                    ...action.payload,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_CUSTOMER_TYPE_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_CUSTOMER_TYPE_FAIL });
                });
                break;

            
            case actionTypes.ON_SELECT_DISTRIBUTOR:
                const plans = getState().appReducer.funnelData.plans
                const distributorCode = action.payload;

                let filteredPlans = filter(plans, (i)=> {
                    return has(i.services, distributorCode)
                })
                
                dispatch({ type: actionTypes.SET_FILTERED_PLANS, payload: filteredPlans });

                break;

            case actionTypes.FETCH_DISTRIBUTORS:
                let commodities = [];
                let distributorURL = `/products/postcode-distributor?`

                if (getState().appReducer.commodityType.data.electricity)
                    distributorURL += 'commodityTypes=Electricity'

                if (getState().appReducer.commodityType.data.gas)
                    distributorURL += '&commodityTypes=Gas'

                // if (getState().appReducer.commodityType.data.gas)
                //     commodities.push('Gas');

                dispatch({ type: actionTypes.API_DISTRIBUTORS_START });

                api.get(distributorURL, {
                    params: {
                        postCode: getState().appReducer.customerAccount.postCode,
                        quoteNumber: getState().appReducer.quoteNumber,
                    }
                })
                .then((response) => {
                    dispatch({ 
                        type: actionTypes.API_DISTRIBUTORS_SUCCESS,
                        payload: response.data 
                    });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_DISTRIBUTORS_FAIL });
                });
                break;
                
            case actionTypes.ON_SELECT_USE_SITE_ADDRESS_SUGGEST:
                let thePayload = {}

                if(action.payload === true) {
                    thePayload = {
                        siteAddress: getState().appReducer.siteAddressSuggest.AddressFull,
                        SiteApartmentNumber: getState().appReducer.siteAddressSuggest.UnitNumber,
                        SiteApartmentType: getState().appReducer.siteAddressSuggest.UnitType,
                        SiteBuildingName: getState().appReducer.siteAddressSuggest.BuildingName,
                        SiteFloorNumber: getState().appReducer.siteAddressSuggest.LevelNumber,
                        SiteFloorType: getState().appReducer.siteAddressSuggest.LevelType,
                        SiteLocationDescriptor: null,
                        SiteLotNumber: null,
                        SitePostCode: getState().appReducer.siteAddressSuggest.Postcode,
                        SiteState: getState().appReducer.siteAddressSuggest.State,
                        SiteStreetName: getState().appReducer.siteAddressSuggest.Street,
                        SiteStreetNumber: getState().appReducer.siteAddressSuggest.Number,
                        SiteStreetNumberSuffix: getState().appReducer.siteAddressSuggest.NumberLast,
                        SiteStreetSuffix: getState().appReducer.siteAddressSuggest.StreetSuffix,
                        SiteStreetType: getState().appReducer.siteAddressSuggest.StreetType,
                        SiteSuburb: getState().appReducer.siteAddressSuggest.Suburb,
                        streetLineSite: getState().appReducer.siteAddressSuggest.StreetLine
                    }
                    
                    // If Use Suggested Site Address, then DISCOVER_NMI_MIRN
                    dispatch({ 
                        type: actionTypes.DISCOVER_NMI_MIRN, 
                        payload: getState().appReducer.siteAddressSuggest.AddressFull
                    });
                }

                dispatch({ 
                    type: actionTypes.SET_USE_SITE_ADDRESS_SUGGEST, 
                    payload: {
                        isUseSiteAddressSuggest: action.payload,
                        confirmedPropertyDetails: {
                            data: thePayload
                        }
                    } 
                });
                
                break;
                
            // case actionTypes.ON_SELECT_SITE_POSTAL_SAME:
            //     dispatch({ 
            //         type: actionTypes.SET_SITE_POSTAL_SAME, payload: action.payload 
            //     });
            //     break;

            case actionTypes.ON_SUBMIT_PROPERTY_DETAILS:
                dispatch({ type: actionTypes.API_CONFIRMED_PROPERTY_DETAILS_START });

                api.put(`/customer/winback/confirmation-property-details`, {
                    ...getState().appReducer.confirmedPropertyDetails.data,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_CONFIRMED_PROPERTY_DETAILS_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_CONFIRMED_PROPERTY_DETAILS_FAIL });
                });
                break;

            case actionTypes.SUBMIT_LIFE_SUPPORT:
                dispatch({ type: actionTypes.API_LIFE_SUPPORT_START });

                api.put(`/customer/winback/life-support`, {
                    lifeSupport: getState().appReducer.lifeSupport.isRequired,
                    quoteNumber: getState().appReducer.quoteNumber,
                    lifeSupportTypeElectricity: getState().appReducer.lifeSupport.type.electricity,
                    lifeSupportTypeGas: getState().appReducer.lifeSupport.type.gas,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_LIFE_SUPPORT_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_LIFE_SUPPORT_FAIL });
                });
                break;

            case actionTypes.SUBMIT_MOVING_HOUSE:
                dispatch({ type: actionTypes.API_MOVING_HOUSE_START });

                api.put(`/customer/winback/moving-house`, {
                    movingHouse: getState().appReducer.movingHouse.isMoving,
                    proposedMoveDate: getState().appReducer.movingHouse.moveInDate,
                    quoteNumber: getState().appReducer.quoteNumber,
                })
                .then((response) => {
                    dispatch({ type: actionTypes.API_MOVING_HOUSE_SUCCESS });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.API_MOVING_HOUSE_FAIL });
                });
                break;

                case actionTypes.SUBMIT_CONCESSION_REQUIRED:
                    
                    if(getState().appReducer.isRequiredConcession) {
                        dispatch({ 
                            type: actionTypes.SET_CONCESSION_CARD, 
                            payload: {
                                type: action.payload.concessionCardType,
                                number: action.payload.concessionCardNumber,
                                expiryDate: action.payload.concessionCardExpiryDate
                            }
                        });
                    } else {
                        dispatch({type: actionTypes.RESET_CONCESSION_CARD});
                    }
                    
                    dispatch({ type: actionTypes.API_CONCESSION_REQUIRED_START });

                    api.put(`/customer/winback/concession-card`, {
                        concessionCardRequired: getState().appReducer.concessionCard.isRequired,
                        ...action.payload,  
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({ type: actionTypes.API_CONCESSION_REQUIRED_SUCCESS });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_CONCESSION_REQUIRED_FAIL });
                    });
                    break;

                case actionTypes.ON_SUBMIT_AUTHENTICATION_DETAILS:
                    console.log(action.payload);
                    dispatch({ type: actionTypes.API_AUTHENTICATION_ID_START });

                    api.put(`/customer/authentications`, {
                        ...action.payload,
                        concentForCC: getState().appReducer.authenticationId.input.concentForCC,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({ type: actionTypes.API_AUTHENTICATION_ID_SUCCESS });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_AUTHENTICATION_ID_FAIL });
                    });
                    break;

                case actionTypes.SUBMIT_EIC_SUBMISSION:
                    dispatch({ type: actionTypes.API_EIC_SUBMISSION_START });

                    api.put(`/customer/winback/submission`, {
                        ...action.payload,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_EIC_SUBMISSION_SUCCESS,
                            payload: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_EIC_SUBMISSION_FAIL });
                    });
                    break;

                case actionTypes.SET_HEAR_US_IN_FUTURE:
                    dispatch({ type: actionTypes.API_HEAR_US_IN_FUTURE_START });

                    api.put(`/customer/product-update`, {
                        productUpdate: action.payload,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_HEAR_US_IN_FUTURE_SUCCESS,
                            payload: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_HEAR_US_IN_FUTURE_FAIL });
                    });
                    break;

                case actionTypes.SUBMIT_SIGNUP:
                    let APIEndpoint=null;

                    if(getState().appReducer.decision.data === 'winback' ){
                        APIEndpoint = `/customer/signup?quoteNumber=${getState().appReducer.quoteNumber}`;
                    } else if(getState().appReducer.decision.data === 'cancellationretention' ){
                        APIEndpoint = `/customer/cancellation-retention/submit-to-retention-portal?quoteNumber=${getState().appReducer.quoteNumber}`;
                    }

                    dispatch({ type: actionTypes.API_SUBMIT_SIGNUP_START });

                    api.put(APIEndpoint)
                    .then((response) => {
                        if(response.data === true){
                            dispatch({
                                type: actionTypes.API_SUBMIT_SIGNUP_SUCCESS,
                                payload: response.data,
                            });
                        }
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_SUBMIT_SIGNUP_FAIL });
                    });
                    break;

                case actionTypes.ON_SELECT_RETENTION_SUCCESS:
                    dispatch({ type: actionTypes.API_RETENTION_START });

                    api.put(`/customer/retention/success`, {
                        success: action.payload,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        if(response.data === true){
                            dispatch({
                                type: actionTypes.API_RETENTION_SUCCESS
                            });
                        }
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_RETENTION_FAIL });
                    });
                    break;

                // case actionTypes.ON_PRODUCT_PAGE_LOAD:
                //     dispatch({ type: actionTypes.API_FUNNEL_DATA_START });
                //     let electricityAvailable = getState().appReducer.commodityType.data.electricity;
                //     let gasAvailable = getState().appReducer.commodityType.data.gas;
                //     let interestedIn = [];

                //     if(electricityAvailable)
                //         interestedIn.push('Electricity')

                //     if(gasAvailable)
                //         interestedIn.push('Gas')

                //     api.put(`/products/funnelData`, {
                //         billing: null,       // Change me after POC
                //         funnel: 'Retention',
                //         electricityAvailable: electricityAvailable,
                //         gasAvailable: gasAvailable,
                //         interestedIn: interestedIn,
                //         internetAvailable: false,
                //         prospectType: getState().appReducer.customerType.data,
                //         postCode: getState().appReducer.customerAccount.postCode,
                //         state: getState().appReducer.customerAccount.state,
                //         suburb: getState().appReducer.customerAccount.suburb,
                //         quoteNumber: getState().appReducer.quoteNumber,
                //     })
                //     .then((response) => {
                //         dispatch({
                //             type: actionTypes.API_FUNNEL_DATA_SUCCESS,
                //             payload: response.data
                //             //payload: dummyFunnelData()
                //         });
                //     })
                //     .catch((error) => {
                //         dispatch({ type: actionTypes.API_FUNNEL_DATA_FAIL });
                //     });
                // break;
                
                case actionTypes.ON_REQUEST_TARRIF:
                    dispatch({ type: actionTypes.API_TARRIF_START });
                    
                    api.put(`/products/tariffs`, {
                        campaign: getState().appReducer.campaign.data,
                        postCode: getState().appReducer.customerAccount.postCode,
                        productName: action.payload.productName,
                        prospectType: getState().appReducer.customerType.data,
                        state: getState().appReducer.customerAccount.state,
                        suburb: getState().appReducer.customerAccount.suburb,
                        quoteNumber: getState().appReducer.quoteNumber,
                        streetLine: getState().appReducer.customerAccount.streetLine,
                    })
                    .then((response) => {
                        let tarrifData = {};
                        tarrifData[action.payload.productName] = response.data;
                        let tariffs = null;
                        let tarrifOptions = [];
                        // Map Eletricity Tarrifs for Dropdown
                        if(response.data && response.data.electricity && response.data.electricity[0] && response.data.electricity[0].tariffs){
                            tariffs = response.data.electricity[0].tariffs
                            tarrifOptions = map(tariffs, 'name')
                            tarrifOptions = union(tarrifOptions, getState().appReducer.tarrifOptions)
                        }

                        dispatch({
                            type: actionTypes.API_TARRIF_SUCCESS,
                            payload: {
                                tarrifData: tarrifData,
                                tarrifOptions: tarrifOptions
                            }
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_TARRIF_FAIL });
                    });
                break;

                case actionTypes.ON_LOGO_CLICK:
                    dispatch({ type: actionTypes.SET_SHOW_RESET_WARNING, payload: true });
                    break;

                case actionTypes.SET_LIFE_SUPPORT_REQUIRED:
                    if(action.payload === false) {
                        dispatch({ type: actionTypes.RESET_LIFE_SUPPORT_TYPE });
                    }
                    break;

                case actionTypes.SET_MOVING_HOUSE:
                    if(action.payload === false) {
                        dispatch({ type: actionTypes.RESET_MOVEIN_DATE });
                    }
                    break;

                case actionTypes.ON_CHANGE_MOVEIN_DATE:
                    dispatch({ 
                        type: actionTypes.SET_MOVEIN_DATE, payload: action.payload 
                    });
                    break;

                case actionTypes.ON_SELECT_SIGNUP_TO_DEBIT:
                    dispatch({ 
                        type: actionTypes.SET_SIGNUP_TO_DEBIT, payload: action.payload 
                    });
                    break;

                case actionTypes.ON_SUBMIT_DEBIT_INFO:
                    dispatch({ type: actionTypes.API_DEBIT_START });

                    api.put(`/customer/winback/direct-debit`, {
                        paywayToken: action.payload,
                        directDebit: getState().appReducer.debit.isSignup,
                        paymentMethod: getState().appReducer.debit.paymentMethod,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({ type: actionTypes.API_DEBIT_SUCCESS });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_DEBIT_FAIL, status: 'failed' });
                    });

                    break;

                // case actionTypes.ON_CHANGE_POSTAL_ADDRESS:
                //     dispatch({ 
                //         type: actionTypes.SET_POSTAL_ADDRESS, payload: action.payload 
                //     });
                //     break;

                case actionTypes.ON_SELECT_CALL_OUTCOME:
                    let showComment = false;

                    if(action.payload === 'Quote Provided' ||
                        action.payload === 'Save - Retention Plan' ||
                        action.payload === 'Save - Sale' ||
                        action.payload === 'Save - Standard Plan') {
                            dispatch({ type: actionTypes.SET_SAVE_DETAILS_STEP });
                    } else {
                        dispatch({ type: actionTypes.REMOVE_SAVE_DETAILS_STEP });
                        showComment = true;
                    }

                    dispatch({ 
                        type: actionTypes.SET_CALL_OUTCOME, 
                        payload: {
                            showComment: showComment,
                            data: {
                                outcome: action.payload,
                                outcomeComment: null    //Reset outcomeComment everytime call outcome has changed
                            }
                        }
                    });

                    break;

                case actionTypes.ON_SUBMIT_CALL_OUTCOME:
                    dispatch({ type: actionTypes.API_CALL_OUTCOME_START });

                    api.put(`/customer/cancellation-retention/out-come`, {
                        ...action.payload,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({ type: actionTypes.API_CALL_OUTCOME_SUCCESS });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_CALL_OUTCOME_FAIL, status: 'failed' });
                    });

                    break;

                case actionTypes.ON_SUBMIT_DETAILS:
                    dispatch({ type: actionTypes.API_DETAILS_START });

                    api.put(`/customer/cancellation-retention/details`, {
                        ...action.payload,
                        creditsOffered: getState().appReducer.details.data.creditsOffered,
                        quoteNumber: getState().appReducer.quoteNumber,
                    })
                    .then((response) => {
                        dispatch({ type: actionTypes.API_DETAILS_SUCCESS });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_DETAILS_FAIL, status: 'failed' });
                    });

                    break;

                case actionTypes.INIT_CONFIRMED_CUSTOMER_DETAILS:
                    
                    dispatch({ 
                        type: actionTypes.INIT_UNSTRUCTURED_SITE_ADDRESS, 
                        payload: getState().appReducer.confirmedPropertyDetails.data.siteAddress
                    });
                    break;

                case actionTypes.INIT_UNSTRUCTURED_SITE_ADDRESS:
                    dispatch({ type: actionTypes.API_UNSTRUCTURED_SITE_ADDRESS_START });

                    api.get(`https://api.addressify.com.au/addresspro/autocomplete?api_key=${process.env.REACT_APP_ADDRESSIFY_API_KEY}`, {
                        params: {
                            max_results: 1,
                            address_types: 2,
                            info: true,
                            close_matches: true,
                            term: action.payload
                        },
                    })
                    .then((response) => {
                        console.log('INIT_UNSTRUCTURED_SITE_ADDRESS', response.data)
                        
                        dispatch({ type: actionTypes.API_UNSTRUCTURED_SITE_ADDRESS_SUCCESS });
                        
                        dispatch({
                            type: actionTypes.SET_SUGGEST_SITE_ADDRESS,
                            payload: response.data[0]
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_UNSTRUCTURED_SITE_ADDRESS_FAIL, payload: error });
                    });
                    break;

                case actionTypes.DISCOVER_NMI_MIRN:
                    // Discover NMI & MIRN
                    dispatch({ type: actionTypes.API_NMI_MIRN_DISCOVERY_START });

                    api.get(`/api/address/nmi-mirn-discoveries-by-addressify`, {
                        params: {
                            address: action.payload
                        },
                    })
                    .then((response) => {
                        dispatch({
                            type: actionTypes.API_NMI_MIRN_DISCOVERY_SUCCESS,
                            payload: response.data[0]
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.API_NMI_MIRN_DISCOVERY_FAIL, payload: error });
                    });
                    break;
            default:
                break;
        }
    };

export const loyaltyMDL = [portalFlow];
