import { combineReducers } from 'redux';
import userReducer from './users-reducer';
import uiReducer from './ui-reducer'
import authReducer from './auth-reducer'
import dashboardReducer from './dashboard-reducer'
import appReducer from './app-reducer'


const rootReducer = combineReducers({
    authReducer,
    appReducer,
    userReducer,
    uiReducer,
    dashboardReducer
});

export default rootReducer;