export const SUCCESS = true;
export const FAILED = false;
export const UNSET = null;

export const GET_USERS = "GET_USERS";
export const API_ERROR = "API_ERROR";
export const API_UNAUTHORIZED = "API_UNAUTHORIZED";

export const LOADING_START = "LOADING_START";
export const LOADING_SUCCESS = "LOADING_SUCCESS";
export const LOADING_FAIL = "LOADING_FAIL";

export const DISMISS_ERROR = "DISMISS_ERROR";
export const ON_ERROR = "ON_ERROR";
export const RESET_STATUS = "RESET_STATUS";

//Authentication
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const SET_SPLASH_WAIT = "[internal process] SET_SPLASH_WAIT";
export const RESET_SPLASH_WAIT = "[internal process] RESET_SPLASH_WAIT";

export const ON_SUBMIT_ADDRESS = "[ui action] ON_SUBMIT_ADDRESS";
export const SET_SEARCH_MODE = "[ui action] SET_SEARCH_MODE";
export const SET_ADDRESS_POLICY = "[internal process] SET_ADDRESS_POLICY";
export const SET_CUSTOMER_ADDRESS = "[ui action] SET_CUSTOMER_ADDRESS";
export const PERSIST_ADRESS_SEARCH_STATE = "[ui action] PERSIST_ADRESS_SEARCH_STATE";
export const API_ADDRESS_QUALIFICATION_START = "[api request] ADDRESS_QUALIFICATION_START";
export const API_ADDRESS_QUALIFICATION_SUCCESS = "[api request] ADDRESS_QUALIFICATION_SUCCESS";
export const API_ADDRESS_QUALIFICATION_FAIL = "[api request] ADDRESS_QUALIFICATION_FAIL";

export const SET_LIFESUPPORT_NOTIFICATION = "[ui] SET_LIFESUPPORT_NOTIFICATION";
export const SET_SECURITY_ALARM_NOTIFICATION = "[ui] SET_SECURITY_ALARM_NOTIFICATION";
export const ON_SELECT_COMMODITY_TYPE = "[ui] ON_SELECT_COMMODITY_TYPE";
export const ON_SELECT_EXISTING_CUSTOMER = "[ui] ON_SELECT_EXISTING_CUSTOMER";
export const ON_SUBMIT_ACCOUNT_DETAILS = "[ui] ON_SUBMIT_ACCOUNT_DETAILS";
export const SET_ACCOUNT_DETAILS = "[internal process] SET_ACCOUNT_DETAILS";
export const API_ACCOUNT_DETAILS_START = "[api request] ACCOUNT_DETAILS_START";
export const API_ACCOUNT_DETAILS_SUCCESS = "[api request] ACCOUNT_DETAILS_SUCCESS";
export const API_ACCOUNT_DETAILS_FAIL = "[api request] ACCOUNT_DETAILS_FAIL";

export const GET_TELCO_DETAILS = "[ui action] GET_TELCO_DETAILS"
export const ON_SELECT_PRODUCT = "[ui action] ON_SELECT_PRODUCT"
export const API_SUBMIT_PRODUCT_START = "[api request] SUBMIT_PRODUCT_START"
export const API_SUBMIT_PRODUCT_SUCCESS = "[api request] SUBMIT_PRODUCT_SUCCESS"
export const API_SUBMIT_PRODUCT_FAIL = "[api request] SUBMIT_PRODUCT_FAIL"
export const API_TELCO_DETAILS_START = "[api request] TELCO_DETAILS_START"
export const API_TELCO_DETAILS_SUCCESS = "[api request] TELCO_DETAILS_SUCCESS"
export const API_TELCO_DETAILS_FAIL = "[api request] TELCO_DETAILS_FAIL"

export const ON_SELECT_CONTRACT_TERM = "[ui action] ON_SELECT_CONTRACT_TERM"
export const ON_SELECT_MODEM = "[ui action] ON_SELECT_MODEM"
export const ON_SELECT_ACTIVE_CONNECTION = "[ui action] ON_SELECT_ACTIVE_CONNECTION"
export const ON_SELECT_ACC_HOLDER = "[ui action] ON_SELECT_ACC_HOLDER"
export const ON_SUBMIT_SERVICE_INFO = "[ui action] ON_SUBMIT_SERVICE_INFO"
export const API_SERVICE_INFO_START = "[api request] SERVICE_INFO_START"
export const API_SERVICE_INFO_SUCCESS = "[api request] SERVICE_INFO_SUCCESS"
export const API_SERVICE_INFO_FAIL = "[api request] SERVICE_INFO_FAIL"

export const ON_ADD_PHONE = "[ui action] ON_ADD_PHONE"
export const ON_ADD_NEW_NUMBER = "[ui action] ON_ADD_NEW_NUMBER"
export const ON_AGREE_CSG = "[ui action] ON_AGREE_CSG"
export const ON_SUBMIT_SERVICE_ADDONS = "[ui action] ON_SUBMIT_SERVICE_ADDONS"
export const API_SERVICE_ADDONS_START = "[api request] SERVICE_ADDONS_START"
export const API_SERVICE_ADDONS_SUCCESS = "[api request] SERVICE_ADDONS_SUCCESS"
export const API_SERVICE_ADDONS_FAIL = "[api request] SERVICE_ADDONS_FAIL"

export const ON_SUBMIT_ADDRESS_DETAILS = "[ui action] ON_SUBMIT_ADDRESS_DETAILS"
export const ON_SELECT_INVOICE = "[ui action] ON_SELECT_INVOICE"
export const API_ADDRESS_DETAILS_START = "[api request] ADDRESS_DETAILS_START"
export const API_ADDRESS_DETAILS_SUCCESS = "[api request] ADDRESS_DETAILS_SUCCESS"
export const API_ADDRESS_DETAILS_FAIL = "[api request] ADDRESS_DETAILS_FAIL"

export const ON_SUBMIT_AUTHENTICATION_DETAILS = "[ui action] ON_SUBMIT_AUTHENTICATION_DETAILS"
export const ON_AUTH_CHANGE_CONSENT = "[ui action] ON_AUTH_CHANGE_CONSENT"
export const API_AUTHENTICATION_ID_START = "[api request] AUTHENTICATION_ID_START"
export const API_AUTHENTICATION_ID_SUCCESS = "[api request] AUTHENTICATION_ID_SUCCESS"
export const API_AUTHENTICATION_ID_FAIL = "[api request] AUTHENTICATION_ID_FAIL"

export const ON_CHANGE_CONSENT = "[ui action] ON_CHANGE_CONSENT"
export const ON_SUBMIT_CONSENT = "[ui action] ON_SUBMIT_CONSENT"
export const API_CONSENT_START = "[api request] CONSENT_START"
export const API_CONSENT_SUCCESS = "[api request] CONSENT_SUCCESS"
export const API_CONSENT_FAIL = "[api request] CONSENT_FAIL"

export const INIT_COSTS = "[internal process] INIT_COSTS"
export const API_COSTS_START = "[api request] COSTS_START"
export const API_COSTS_SUCCESS = "[api request] COSTS_SUCCESS"
export const API_COSTS_FAIL = "[api request] COSTS_FAIL"

export const SET_PAYMENT_METHOD = "[ui action] SET_PAYMENT_METHOD";
export const ON_SUBMIT_PAYMENT = "[ui action] ON_SUBMIT_PAYMENT"
export const API_PAYMENT_START = "[api request] PAYMENT_START"
export const API_PAYMENT_SUCCESS = "[api request] PAYMENT_SUCCESS"
export const API_PAYMENT_FAIL = "[api request] PAYMENT_FAIL"

export const TOGGGLE_OLDMAN = "[ui action] TOGGGLE_OLDMAN";
export const ON_SELECT_SECONDARY_ACCOUNT = "[ui action] ON_SELECT_SECONDARY_ACCOUNT";
export const ON_SUBMIT_CONFIRMED_CUSTOMER_DETAILS = "[ui action] ON_SUBMIT_CONFIRMED_CUSTOMER_DETAILS";
export const SET_CONFIRMED_CUSTOMER_DETAILS = "[ui action] SET_CONFIRMED_CUSTOMER_DETAILS";
export const API_CONFIRMED_CUSTOMER_DETAILS_START = "[api request] CONFIRMED_CUSTOMER_DETAILS_START"
export const API_CONFIRMED_CUSTOMER_DETAILS_SUCCESS = "[api request] CONFIRMED_CUSTOMER_DETAILS_SUCCESS"
export const API_CONFIRMED_CUSTOMER_DETAILS_FAIL = "[api request] CONFIRMED_CUSTOMER_DETAILS_FAIL"

export const GET_LEAD_TIME = "[internal process] GET_LEAD_TIME"
export const API_LEAD_TIME_START = "[api request] API_LEAD_TIME_START"
export const API_LEAD_TIME_SUCCESS = "[api request] API_LEAD_TIME_SUCCESS"
export const API_LEAD_TIME_FAIL = "[api request] API_LEAD_TIME_FAIL"
export const API_SUBMIT_SIGNUP_START = "[api request] SUBMIT_SIGNUP_START"
export const API_SUBMIT_SIGNUP_SUCCESS = "[api request] SUBMIT_SIGNUP_SUCCESS"
export const API_SUBMIT_SIGNUP_FAIL = "[api request] SUBMIT_SIGNUP_FAIL"
export const SET_SIGNUP_FEEDBACK = "[internal process] SET_SIGNUP_FEEDBACK"
export const DONE_READING_TNC = "[internal process] DONE_READING_TNC"

export const INIT_TELCO_RESUME = "[internal process] INIT_TELCO_RESUME"
export const TELCO_RESUME_DONE = "[internal process] TELCO_RESUME_DONE"
export const API_TELCO_RESUME_START = "[api request] TELCO_RESUME_START"
export const API_TELCO_RESUME_SUCCESS = "[api request] TELCO_RESUME_SUCCESS"
export const API_TELCO_RESUME_FAIL = "[api request] TELCO_RESUME_FAIL"

//===========================================================

export const SET_FILTERED_PLANS = "[ui action] SET_FILTERED_PLANS";
export const SET_DECISION = "SET_DECISION";
export const ON_SELECT_SIGNUP_TO_DEBIT = "[ui action] ON_SELECT_SIGNUP_TO_DEBIT";
export const SET_SIGNUP_TO_DEBIT = "[internal process] SET_SIGNUP_TO_DEBIT";
export const ON_SUBMIT_DEBIT_INFO = "[ui action] ON_SUBMIT_DEBIT_INFO";
export const API_DEBIT_START = "[api request] DEBIT_START";
export const API_DEBIT_SUCCESS = "[api request] DEBIT_SUCCESS";
export const API_DEBIT_FAIL = "[api request] DEBIT_FAIL";

export const CLOSE_DECISION_SPLASH = "CLOSE_DECISION_SPLASH";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_STEPS = "SET_STEPS";
export const SET_WINBACK_STEPS = "SET_WINBACK_STEPS";
export const SET_CANCELLATION_RETENTION_STEPS = "SET_CANCELLATION_RETENTION_STEPS";
export const SET_RETENTION_STEPS = "SET_RETENTION_STEPS";
export const SET_INITIAL_STEPS = "SET_INITIAL_STEPS";
export const RESET_STEPS = "RESET_STEPS";

export const ON_SEARCH_CUSTOMER_ACCOUNT = "[ui action] ON_SEARCH_CUSTOMER_ACCOUNT";
export const API_CUSTOMER_ACCOUNT_START = "[api request] CUSTOMER_ACCOUNT_START";
export const API_CUSTOMER_ACCOUNT_SUCCESS = "[api request] CUSTOMER_ACCOUNT_SUCCESS";
export const API_CUSTOMER_ACCOUNT_FAIL = "[api request] CUSTOMER_ACCOUNT_FAIL";

export const START_PORTAL = "[ui] START_PORTAL"
export const GENERATE_UUID = "[internal process] GENERATE_UUID"
export const API_QUOTE_REFERENCE_START = "[api request] QUOTE_REFERENCE_START"
export const API_QUOTE_REFERENCE_SUCCESS = "[api request] QUOTE_REFERENCE_SUCCESS"
export const API_QUOTE_REFERENCE_FAIL = "[api request] QUOTE_REFERENCE_FAIL"

export const SELECT_CAMPAIGN = "[ui] SELECT_CAMPAIGN"
export const SET_SELECTED_CAMPAIGN = "[ui] SET_SELECTED_CAMPAIGN";
export const SELECT_DECISION = "[ui] SELECT_DECISION"
export const SET_COMPLETED = "[ui action] SET_COMPLETED"

export const API_CAMPAIGN_START = "[api request] CAMPAIGN_START"
export const API_CAMPAIGN_SUCCESS = "[api request] CAMPAIGN_SUCCESS"
export const API_CAMPAIGN_FAIL = "[api request] CAMPAIGN_FAIL"

export const API_DECISION_START = "[api request] DECISION_START"
export const API_DECISION_SUCCESS = "[api request] DECISION_SUCCESS"
export const API_DECISION_FAIL = "[api request] DECISION_FAIL"

export const SET_CANCELLATION_REASON = "[ui action] SET_CANCELLATION_REASON"
export const API_CANCELLATION_REASON_START = "[api request] CANCELLATION_REASON_START"
export const API_CANCELLATION_REASON_SUCCESS = "[api request] CANCELLATION_REASON_SUCCESS"
export const API_CANCELLATION_REASON_FAIL = "[api request] CANCELLATION_REASON_FAIL"

export const SUBMIT_COMMODITY_TYPE = "[ui] SUBMIT_COMMODITY_TYPE";
export const SET_COMMODITY_TYPE = "[ui action] SET_COMMODITY_TYPE";
export const API_COMMODITY_TYPE_START = "[api request] COMMODITY_TYPE_START"
export const API_COMMODITY_TYPE_SUCCESS = "[api request] COMMODITY_TYPE_SUCCESS"
export const API_COMMODITY_TYPE_FAIL = "[api request] COMMODITY_TYPE_FAIL"

export const ON_SELECT_CUSTOMER_TYPE = "[ui action] ON_SELECT_CUSTOMER_TYPE";
export const SET_CUSTOMER_TYPE = "[ui action] SET_CUSTOMER_TYPE";
export const API_CUSTOMER_TYPE_START = "[api request] CUSTOMER_TYPE_START"
export const API_CUSTOMER_TYPE_SUCCESS = "[api request] CUSTOMER_TYPE_SUCCESS"
export const API_CUSTOMER_TYPE_FAIL = "[api request] CUSTOMER_TYPE_FAIL"

export const ON_SELECT_DISTRIBUTOR = "[ui action] ON_SELECT_DISTRIBUTOR";
export const FETCH_DISTRIBUTORS = "[internal process] FETCH_DISTRIBUTORS";
export const API_DISTRIBUTORS_START = "[api request] DISTRIBUTORS_START"
export const API_DISTRIBUTORS_SUCCESS = "[api request] DISTRIBUTORS_SUCCESS"
export const API_DISTRIBUTORS_FAIL = "[api request] DISTRIBUTORS_FAIL"

export const ON_SELECT_SITE_POSTAL_SAME = "[ui action] ON_SELECT_SITE_POSTAL_SAME";
export const ON_CHANGE_SITE_ADDRESS = "[ui action] ON_CHANGE_SITE_ADDRESS";
export const ON_CHANGE_POSTAL_ADDRESS = "[ui action] ON_CHANGE_POSTAL_ADDRESS";
export const SET_SITE_FULL_ADDRESS = "[internal process] SET_SITE_FULL_ADDRESS";
export const SET_SITE_UNSTRUCTURED_ADDRESS = "[internal process] SET_SITE_UNSTRUCTURED_ADDRESS";
export const SET_SUGGEST_SITE_ADDRESS = "[internal process] SET_SUGGEST_SITE_ADDRESS";
export const ON_SELECT_USE_SITE_ADDRESS_SUGGEST = "[ui action] ON_SELECT_USE_SITE_ADDRESS_SUGGEST";
export const SET_USE_SITE_ADDRESS_SUGGEST = "[internal process] SET_USE_SITE_ADDRESS_SUGGEST";
export const SET_POSTAL_ADDRESS = "[internanl process] SET_POSTAL_ADDRESS";
export const SET_SITE_POSTAL_SAME = "[internal process] SET_SITE_POSTAL_SAME";

export const ON_PAGE_LOAD_CC_DETAILS = "[internal process] ON_PAGE_LOAD_CC_DETAILS";
export const INIT_CONFIRMED_CUSTOMER_DETAILS = "[internal process] INIT_CONFIRMED_CUSTOMER_DETAILS";

export const ON_SUBMIT_PROPERTY_DETAILS = "[ui action] ON_SUBMIT_PROPERTY_DETAILS";
export const ON_SELECT_COMMUNICATION_METHOD = "[ui action] ON_SELECT_COMMUNICATION_METHOD";
export const API_CONFIRMED_PROPERTY_DETAILS_START = "[api request] CONFIRMED_PROPERTY_DETAILS_START"
export const API_CONFIRMED_PROPERTY_DETAILS_SUCCESS = "[api request] CONFIRMED_PROPERTY_DETAILS_SUCCESS"
export const API_CONFIRMED_PROPERTY_DETAILS_FAIL = "[api request] CONFIRMED_PROPERTY_DETAILS_FAIL"
export const INIT_UNSTRUCTURED_SITE_ADDRESS = "[internal process] INIT_UNSTRUCTURED_SITE_ADDRESS"
export const API_UNSTRUCTURED_SITE_ADDRESS_START = "[api request] UNSTRUCTURED_SITE_ADDRESS_START"
export const API_UNSTRUCTURED_SITE_ADDRESS_SUCCESS = "[api request] UNSTRUCTURED_SITE_ADDRESS_SUCCESS"
export const API_UNSTRUCTURED_SITE_ADDRESS_FAIL = "[api request] UNSTRUCTURED_SITE_ADDRESS_FAIL"

export const DISCOVER_NMI_MIRN = "[internal process] DISCOVER_NMI_MIRN"
export const API_NMI_MIRN_DISCOVERY_START = "[api request] NMI_MIRN_DISCOVERY_START"
export const API_NMI_MIRN_DISCOVERY_SUCCESS = "[api request] NMI_MIRN_DISCOVERY_SUCCESS"
export const API_NMI_MIRN_DISCOVERY_FAIL = "[api request] NMI_MIRN_DISCOVERY_FAIL"

export const SET_LIFE_SUPPORT_REQUIRED = "[ui action] SET_LIFE_SUPPORT_REQUIRED";
export const SET_LIFE_SUPPORT_TYPE = "[ui action] SET_LIFE_SUPPORT_TYPE";
export const RESET_LIFE_SUPPORT_TYPE = "[internal process] RESET_LIFE_SUPPORT_TYPE";
export const SUBMIT_LIFE_SUPPORT = "[ui] SUBMIT_LIFE_SUPPORT"
export const API_LIFE_SUPPORT_START = "[api request] LIFE_SUPPORT_START"
export const API_LIFE_SUPPORT_SUCCESS = "[api request] LIFE_SUPPORT_SUCCESS"
export const API_LIFE_SUPPORT_FAIL = "[api request] LIFE_SUPPORT_FAIL"

export const SET_MOVING_HOUSE = "[ui action] SET_MOVING_HOUSE";
export const SET_MOVEIN_DATE = "[internal process] SET_MOVEIN_DATE";
export const RESET_MOVEIN_DATE = "[internal process] RESET_MOVEIN_DATE";
export const ON_CHANGE_MOVEIN_DATE = "[ui action] ON_CHANGE_MOVEIN_DATE";
export const SUBMIT_MOVING_HOUSE = "[ui] SUBMIT_MOVING_HOUSE"
export const API_MOVING_HOUSE_START = "[api request] MOVING_HOUSE_START"
export const API_MOVING_HOUSE_SUCCESS = "[api request] MOVING_HOUSE_SUCCESS"
export const API_MOVING_HOUSE_FAIL = "[api request] MOVING_HOUSE_FAIL"

export const SET_CONCESSION_REQUIRED = "[ui action] SET_CONCESSION_REQUIRED";
export const SET_CONCESSION_CARD = "[internal process] SET_CONCESSION_CARD";
export const RESET_CONCESSION_CARD = "[internal process] RESET_CONCESSION_CARD";
export const SUBMIT_CONCESSION_REQUIRED = "[ui] SUBMIT_CONCESSION_REQUIRED"
export const API_CONCESSION_REQUIRED_START = "[api request] CONCESSION_REQUIRED_START"
export const API_CONCESSION_REQUIRED_SUCCESS = "[api request] CONCESSION_REQUIRED_SUCCESS"
export const API_CONCESSION_REQUIRED_FAIL = "[api request] CONCESSION_REQUIRED_FAIL"

export const ON_SELECT_AUTHENTICATION_TYPE = "[ui action] ON_SELECT_AUTHENTICATION_TYPE"
export const SET_AUTHENTICATION_ID_TYPE = "[internal process] SET_AUTHENTICATION_ID_TYPE"
export const SET_AUTHENTICATION_DETAILS = "[internal process] SET_AUTHENTICATION_DETAILS"


export const ON_LOGO_CLICK = "[ui] ON_LOGO_CLICK";
export const ON_PROCEED_RESET = "[ui] ON_PROCEED_RESET";
export const ON_CANCEL_RESET = "[ui] ON_CANCEL_RESET";
export const SET_SHOW_RESET_WARNING = "[ui action] SET_SHOW_RESET_WARNING";
export const SET_RESET_CARDS = "[ui action] SET_RESET_CARDS";

export const SET_EIC = "[ui action] SET_EIC";
export const SUBMIT_EIC_SUBMISSION = "[ui] SUBMIT_EIC_SUBMISSION"
export const API_EIC_SUBMISSION_START = "[api request] EIC_SUBMISSION_START"
export const API_EIC_SUBMISSION_SUCCESS = "[api request] EIC_SUBMISSION_SUCCESS"
export const API_EIC_SUBMISSION_FAIL = "[api request] EIC_SUBMISSION_FAIL"

export const SET_HEAR_US_IN_FUTURE = "[ui action] SET_HEAR_US_IN_FUTURE";
export const API_HEAR_US_IN_FUTURE_START = "[api request] HEAR_US_IN_FUTURE_START"
export const API_HEAR_US_IN_FUTURE_SUCCESS = "[api request] HEAR_US_IN_FUTURE_SUCCESS"
export const API_HEAR_US_IN_FUTURE_FAIL = "[api request] HEAR_US_IN_FUTURE_FAIL"

export const SUBMIT_SIGNUP = "[ui action] SUBMIT_SIGNUP"


export const ON_SELECT_RETENTION_SUCCESS = "[ui action] ON_SELECT_RETENTION_SUCCESS"
export const API_RETENTION_START = "[api request] RETENTION_START"
export const API_RETENTION_SUCCESS = "[api request] RETENTION_SUCCESS"
export const API_RETENTION_FAIL = "[api request] RETENTION_FAIL"

export const ON_REQUEST_TARRIF = "[internal process] ON_REQUEST_TARRIF"
export const ON_CHANGE_METER_TYPE = "[ui action] ON_CHANGE_METER_TYPE"
export const ON_TOGGLE_RATES = "[ui action] ON_TOGGLE_RATES"
export const API_TARRIF_START = "[api request] TARRIF_START"
export const API_TARRIF_SUCCESS = "[api request] TARRIF_SUCCESS"
export const API_TARRIF_FAIL = "[api request] TARRIF_FAIL"

export const API_HANDLED_CALLBACK = "[internal process] API_HANDLED_CA  LLBACK"

export const ON_SELECT_INTERACTION_TYPE = "[ui action] ON_SELECT_INTERACTION_TYPE"
export const API_INTERACTION_TYPE_START = "[api request] INTERACTION_TYPE_START"
export const API_INTERACTION_TYPE_SUCCESS = "[api request] INTERACTION_TYPE_SUCCESS"
export const API_INTERACTION_TYPE_FAIL = "[api request] INTERACTION_TYPE_FAIL"

export const ON_SUBMIT_CHURN_INFO = "[ui action] ON_SUBMIT_CHURN_INFO"
export const API_CHURN_INFO_START = "[api request] CHURN_INFO_START"
export const API_CHURN_INFO_SUCCESS = "[api request] CHURN_INFO_SUCCESS"
export const API_CHURN_INFO_FAIL = "[api request] CHURN_INFO_FAIL"

export const ON_SELECT_CALL_OUTCOME = "[ui action] ON_SELECT_CALL_OUTCOME"
export const SET_CALL_OUTCOME = "[internal process] SET_CALL_OUTCOME"
export const ON_SUBMIT_CALL_OUTCOME = "[ui action] ON_SUBMIT_CALL_OUTCOME"
export const API_CALL_OUTCOME_START = "[api request] CALL_OUTCOME_START"
export const API_CALL_OUTCOME_SUCCESS = "[api request] CALL_OUTCOME_SUCCESS"
export const API_CALL_OUTCOME_FAIL = "[api request] CALL_OUTCOME_FAIL"

export const ON_SUBMIT_DETAILS = "[ui action] ON_SUBMIT_DETAILS"
export const API_DETAILS_START = "[api request] DETAILS_START"
export const API_DETAILS_SUCCESS = "[api request] DETAILS_SUCCESS"
export const API_DETAILS_FAIL = "[api request] DETAILS_FAIL"

export const SET_SAVE_DETAILS_STEP = "[internal process] SET_SAVE_DETAILS_STEP"
export const REMOVE_SAVE_DETAILS_STEP = "[internal process] REMOVE_SAVE_DETAILS_STEP"

export const ON_SELECT_OFFERED_CREDITS = "[ui action] ON_SELECT_OFFERED_CREDITS"

