import React from 'react';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import './assets/icons/font-awesome';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
    const isProduction = process.env.NODE_ENV === 'production';
    
    return (
        <CacheBuster
            currentVersion={version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
        >
            <BrowserRouter>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                        
                        <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        </CacheBuster>
    );
}

export default App;
