import {API_UNAUTHORIZED, LOADING_FAIL} from "../action-types";

export const apiError = (data) => async (dispatch) => {
    
    console.log("ERROR from apiError", data);
    switch (data.error) {
        case "Unauthorized":

            dispatch({
                type: API_UNAUTHORIZED,
                peyload: { ...data },
            });
            localStorage.removeItem('access_token');
            window.location = '/';
            break;

        default:
            dispatch({
                type: LOADING_FAIL,
                payload: { ...data },
            });
            break;
    }
};
