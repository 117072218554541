import * as actionTypes from "../action-types";

const initialState = {
  isAuthenticated: false,
  access_token: null,
  expiration: null,
  error: {},
  loading: false
}

const authReducer = (state = initialState, { type, payload }) => {
  
  switch (type) {
    case actionTypes.AUTH_START:
      return { ...state, loading: true }
    case actionTypes.AUTH_SUCCESS:
      return { ...state, ...payload }
    case actionTypes.AUTH_FAIL:
      return { 
        ...state, 
        access_token: null,
        expiration: null,
        error: {...payload}, 
      }
    case actionTypes.API_UNAUTHORIZED:
      return { 
        ...state, 
        isAuthenticated: false,
        access_token: null,
        expiration: null,
        error: {...payload}, 
      }
    default:
      return state
  }
}

export default authReducer