import * as actionTypes from "../action-types";
import { SUCCESS, FAILED, UNSET } from "../action-types";
import { getInitialSteps, getInitialAppState } from "../StoreHelpers";
import moment from "moment";

const appReducer = (state = getInitialAppState(), { type, payload }) => {
    switch (type) {
        case actionTypes.GENERATE_UUID:
            return { ...state, quoteNumber: payload };

        case actionTypes.RESET_STATUS:
            return {
                ...state,
                [payload]: {
                    ...state[payload],
                    status: null,
                },
            };

        case actionTypes.RESET_SPLASH_WAIT:
            return {
                ...state,
                splashWait: {
                    ...state.splashWait,
                    show: null,
                },
            };

        case actionTypes.SET_SPLASH_WAIT:
            return {
                ...state,
                splashWait: {
                    ...state.splashWait,
                    ...payload,
                },
            };

        case actionTypes.SET_CUSTOMER_ADDRESS:
            return {
                ...state,
                customerAddress: {
                    ...state.customerAddress,
                    input: payload.AddressFull,
                    unstructured: payload,
                    data: {}
                },
            };

        case actionTypes.PERSIST_ADRESS_SEARCH_STATE:
            return {
                ...state,
                customerAddress: payload,
            };

        case actionTypes.SET_SEARCH_MODE:
            return {
                ...state,
                customerAddress: {
                    ...state.customerAddress,
                    input: '',
                    data: {},
                    searchMode: payload
                }
            };

        case actionTypes.SET_ADDRESS_POLICY:
            return {
                ...state,
                customerAddress: {
                    ...state.customerAddress,
                    agreeToPolicy: payload
                },
            };

        case actionTypes.API_ADDRESS_QUALIFICATION_START:
            return {
                ...state,
                customerAddress: {
                    ...state.customerAddress,
                    status: "loading",
                },
            };

        case actionTypes.API_ADDRESS_QUALIFICATION_SUCCESS:
            return {
                ...state,
                customerAddress: {
                    ...state.customerAddress,
                    data: {
                        ...payload,
                        // //HACK
                        // serviceable: false
                    },
                    status: "success",
                },
            };
        case actionTypes.API_ADDRESS_QUALIFICATION_FAIL:
            return {
                ...state,
                customerAddress: {
                    ...state.customerAddress,
                    status: "failed",
                },
            };

        case actionTypes.ON_SELECT_COMMODITY_TYPE:
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    input: {
                        ...state.serviceInfo.input,
                        existingCommodityTypes: { ...payload },
                    },
                },
            };

        case actionTypes.ON_SELECT_EXISTING_CUSTOMER:
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    input: {
                        ...state.serviceInfo.input,
                        existingSumoCustomer: payload,
                        existingCommodityTypes: payload==='yes' ? state.serviceInfo.input.existingCommodityTypes : {electricity:false, gas:false, internet:false}
                    },
                },
            };

        case actionTypes.SET_LIFESUPPORT_NOTIFICATION:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    input: {
                        ...state.accountDetails.input,
                        lifeSupport: payload,
                    }

                },
            };

        case actionTypes.SET_SECURITY_ALARM_NOTIFICATION:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    input: {
                        ...state.accountDetails.input,
                        secureAlarm: payload,
                    }
                },
            };

        case actionTypes.SET_ACCOUNT_DETAILS:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    input: {
                        ...state.accountDetails.input,
                        ...payload,
                    },
                },
            };

        case actionTypes.API_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    status: "success",
                },
            };
        case actionTypes.API_ACCOUNT_DETAILS_FAIL:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    status: "failed",
                },
            };

        case actionTypes.ON_SELECT_PRODUCT:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    input: payload,
                },
            };

        case actionTypes.API_TELCO_DETAILS_SUCCESS:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    data: {
                        ...state.plan.data,
                        ...payload   
                    }
                },
            };

        case actionTypes.API_SUBMIT_PRODUCT_SUCCESS:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    status: "success",
                },
            };
        case actionTypes.API_SUBMIT_PRODUCT_FAIL:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    status: "failed",
                },
            };

        case actionTypes.ON_SELECT_MODEM:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    input: {
                        ...state.serviceAddons.input,
                        modemRequired: payload,
                    },
                },
            };

        case actionTypes.ON_SELECT_ACTIVE_CONNECTION:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    input: {
                        ...state.serviceAddons.input,
                        activeConnection: payload,
                    },
                },
            };

        case actionTypes.ON_SELECT_ACC_HOLDER:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    input: {
                        ...state.serviceAddons.input,
                        authorizeAccHolder: payload,
                    },
                },
            };

        case actionTypes.ON_AGREE_CSG:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    input: {
                        ...state.serviceAddons.input,
                        agreeCSG: payload,
                    },
                },
            };

        case actionTypes.ON_SELECT_CONTRACT_TERM:
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    input: {
                        ...state.serviceInfo.input,
                        contractTerm: payload,
                    },
                },
            };

        case actionTypes.ON_SUBMIT_SERVICE_INFO:
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    input: {
                        ...state.serviceInfo.input,
                        ...payload
                    }
                },
            };

        case actionTypes.API_SERVICE_INFO_SUCCESS:
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    status: "success",
                },
            };
        case actionTypes.API_SERVICE_INFO_FAIL:
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    status: "failed",
                },
            };

        case actionTypes.ON_ADD_PHONE:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    input: {
                        ...state.serviceAddons.input,
                        phoneRequired: payload,
                    },
                },
            };

        case actionTypes.ON_ADD_NEW_NUMBER:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    input: {
                        ...state.serviceAddons.input,
                        newNumberRequired: payload,
                    },
                },
            };

        case actionTypes.ON_SUBMIT_SERVICE_ADDONS:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    input: {
                        ...state.serviceAddons.input,
                        ...payload,
                    },
                },
            };

        case actionTypes.API_SERVICE_ADDONS_SUCCESS:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    status: "success",
                },
            };
        case actionTypes.API_SERVICE_ADDONS_FAIL:
            return {
                ...state,
                serviceAddons: {
                    ...state.serviceAddons,
                    status: "failed",
                },
            };

        case actionTypes.ON_SELECT_SECONDARY_ACCOUNT:
            return {
                ...state,
                confirmedCustomerDetails: {
                    ...state.confirmedCustomerDetails,
                    input: {
                        ...state.confirmedCustomerDetails.input,
                        secondAccount: payload,
                    },
                },
            };

        case actionTypes.ON_SUBMIT_CONFIRMED_CUSTOMER_DETAILS:
            return {
                ...state,
                confirmedCustomerDetails: {
                    ...state.confirmedCustomerDetails,
                    input: {
                        ...state.confirmedCustomerDetails.input,
                        ...payload,
                    },
                },
                authenticationId: {
                    ...state.authenticationId,
                    input: {
                        confirmedName: `${payload.customerFirstName} ${payload.customerLastName}`,
                        authenticationName: `${payload.customerFirstName} ${payload.customerLastName}`
                    },
                },
            };

        case actionTypes.API_CONFIRMED_CUSTOMER_DETAILS_SUCCESS:
            return {
                ...state,
                confirmedCustomerDetails: {
                    ...state.confirmedCustomerDetails,
                    status: "success",
                },
            };

        case actionTypes.API_CONFIRMED_CUSTOMER_DETAILS_FAIL:
            return {
                ...state,
                confirmedCustomerDetails: {
                    ...state.confirmedCustomerDetails,
                    status: "failed",
                },
            };

        case actionTypes.ON_CHANGE_SITE_ADDRESS:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    input: {
                        ...state.confirmedAddressDetails.input,
                        siteAddress: payload.AddressFull,
                        siteHouseNumber: payload.Number,
                        siteHouseNumberSuffix: null,
                        sitePostcode: payload.Postcode,
                        siteState: payload.State,
                        siteStreetName: payload.Street,
                        siteStreetType: payload.StreetType,
                        siteStreetTypeSuffix: payload.StreetSuffix,
                        siteSuburb: payload.Suburb,
                        siteUnitNumber: payload.UnitNumber,
                        siteUnitNumberSuffix: null,
                        siteUnitType: payload.UnitType,
                    },
                },
            };

        case actionTypes.ON_CHANGE_POSTAL_ADDRESS:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    input: {
                        ...state.confirmedAddressDetails.input,
                        postalAddress: payload.AddressFull,
                        postalHouseNumber: payload.UnitNumber,
                        postalHouseNumberSuffix: null,
                        postalPostcode: payload.Postcode,
                        postalState: payload.State,
                        postalStreetName: payload.Street,
                        postalStreetType: payload.StreetType,
                        postalStreetTypeSuffix: payload.StreetSuffix,
                        postalSuburb: payload.Suburb,
                        postalUnitNumber: payload.UnitNumber,
                        postalUnitNumberSuffix: payload.Number,
                        postalUnitType: payload.UnitType,
                    },
                },
            };

        case actionTypes.ON_SELECT_SITE_POSTAL_SAME:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    input: {
                        ...state.confirmedAddressDetails.input,
                        siteSameAsPostal: payload,
                    },
                },
            };

        case actionTypes.ON_SUBMIT_ADDRESS_DETAILS:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    input: {
                        ...state.confirmedAddressDetails.input,
                        ...payload,
                    },
                },
            };

        case actionTypes.SET_MOVING_HOUSE:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    input: {
                        ...state.confirmedAddressDetails.input,
                        movingHouse: payload,
                    },
                },
            };

        case actionTypes.SET_MOVEIN_DATE:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    input: {
                        ...state.confirmedAddressDetails.input,
                        proposedMovingDate: payload,
                    },
                },
            };

        case actionTypes.RESET_MOVEIN_DATE:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    input: {
                        ...state.confirmedAddressDetails.input,
                        proposedMovingDate: null,
                    },
                },
            };

        case actionTypes.ON_SELECT_INVOICE:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    input: {
                        ...state.confirmedAddressDetails.input,
                        invoice: payload,
                    },
                },
            };

        case actionTypes.API_ADDRESS_DETAILS_SUCCESS:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    status: "success",
                },
            };

        case actionTypes.API_ADDRESS_DETAILS_FAIL:
            return {
                ...state,
                confirmedAddressDetails: {
                    ...state.confirmedAddressDetails,
                    status: "failed",
                },
            };

        case actionTypes.ON_SELECT_AUTHENTICATION_TYPE:
            return {
                ...state,
                authenticationId: {
                    ...state.authenticationId,
                    input: {
                        ...state.authenticationId.input,
                        authenticationType: payload,
                    },
                },
            };

        case actionTypes.ON_AUTH_CHANGE_CONSENT:
            return {
                ...state,
                authenticationId: {
                    ...state.authenticationId,
                    input: {
                        ...state.authenticationId.input,
                        concentForCC: payload,
                    },
                },
            };

        case actionTypes.ON_SUBMIT_AUTHENTICATION_DETAILS:
            return {
                ...state,
                authenticationId: {
                    ...state.authenticationId,
                    input: {
                        ...state.authenticationId.input,
                        ...payload,
                    },
                },
            };

        case actionTypes.API_AUTHENTICATION_ID_SUCCESS:
            return {
                ...state,
                authenticationId: {
                    ...state.authenticationId,
                    status: "success",
                },
            };

        case actionTypes.API_AUTHENTICATION_ID_FAIL:
            return {
                ...state,
                authenticationId: {
                    ...state.authenticationId,
                    status: "failed",
                },
            };

        case actionTypes.ON_CHANGE_CONSENT:
            return {
                ...state,
                consentAgreement: {
                    ...state.consentAgreement,
                    input: {
                        ...state.consentAgreement.input,
                        ...payload,
                    },
                },
            };

        case actionTypes.API_LEAD_TIME_SUCCESS:
            return {
                ...state,
                leadTime: {
                    ...payload
                }
            };

        case actionTypes.ON_SUBMIT_CONSENT:
            return {
                ...state,
                consentAgreement: {
                    ...state.consentAgreement,
                    input: {
                        ...state.consentAgreement.input,
                        ...payload,
                    },
                },
            };
        
        case actionTypes.DONE_READING_TNC:
            return {
                ...state,
                consentAgreement: {
                    ...state.consentAgreement,
                    input: {
                        ...state.consentAgreement.input,
                        doneReadingTNC: payload,
                    },
                },
            };

        case actionTypes.API_SUBMIT_SIGNUP_SUCCESS:
            return {
                ...state,
                consentAgreement: {
                    ...state.consentAgreement,
                    input: {
                        ...state.consentAgreement.input
                    },
                    status: "success",
                },
            };

        case actionTypes.SET_SIGNUP_FEEDBACK:
            return {
                ...state,
                consentAgreement: {
                    ...state.consentAgreement,
                    input: {
                        ...state.consentAgreement.input,
                        showSignupFeedback: true
                    }
                },
            };

        case actionTypes.API_CONSENT_FAIL:
            return {
                ...state,
                consentAgreement: {
                    ...state.consentAgreement,
                    status: "failed",
                },
            };

        case actionTypes.SET_PAYMENT_METHOD:
            return {
                ...state,
                summaryPayment: {
                    ...state.summaryPayment,
                    input: {
                        ...state.summaryPayment.input,
                        paymentMethod: payload,
                    },
                },
            };

        case actionTypes.API_COSTS_SUCCESS:
            return {
                ...state,
                summaryPayment: {
                    ...state.summaryPayment,
                    data: payload,
                },
            };

        case actionTypes.ON_SUBMIT_PAYMENT:
            return {
                ...state,
                summaryPayment: {
                    ...state.summaryPayment,
                    input: {
                        ...state.summaryPayment.input,
                        ...payload,
                    },
                },
            };

        case actionTypes.API_PAYMENT_SUCCESS:
            return {
                ...state,
                summaryPayment: {
                    ...state.summaryPayment,
                    status: "success",
                },
            };

        case actionTypes.API_PAYMENT_FAIL:
            return {
                ...state,
                summaryPayment: {
                    ...state.summaryPayment,
                    status: "failed",
                },
            };

        case actionTypes.API_TELCO_RESUME_START:
            return {
                ...state,
                resume: {
                    status: "loading",
                },
            };
    
        case actionTypes.API_TELCO_RESUME_SUCCESS:
            return {
                ...state,
                ...payload
            };
    
        case actionTypes.TELCO_RESUME_DONE:
            return {
                ...state,
                resume: {
                    ...state.resume,
                    status: false
                }
            };
    
        //============================================================
        //============================================================

        case actionTypes.API_CUSTOMER_ACCOUNT_START:
            return {
                ...state,
                loading: true,
                customerAccount: {
                    ...payload,
                },
            };

        case actionTypes.API_CUSTOMER_ACCOUNT_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
            };
        case actionTypes.API_CUSTOMER_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                customerAccount: {
                    found: false,
                },
                error: { ...payload },
            };
        case actionTypes.API_NMI_MIRN_DISCOVERY_START:
            return {
                ...state,
                nmiMirnLoading: true,
            };
        case actionTypes.API_NMI_MIRN_DISCOVERY_SUCCESS:
            return {
                ...state,
                confirmedPropertyDetails: {
                    data: {
                        ...state.confirmedPropertyDetails.data,
                        nmi: payload.nmi,
                        mirn: payload.mirn,
                    },
                },
                nmiMirnDiscoverySuccess: true,
                nmiMirnLoading: false,
            };
        case actionTypes.API_NMI_MIRN_DISCOVERY_FAIL:
            return {
                ...state,
                confirmedPropertyDetails: {
                    data: {
                        ...state.confirmedPropertyDetails.data,
                        nmi: null,
                        mirn: null,
                    },
                },
                nmiMirnLoading: false,
                nmiMirnDiscoverySuccess: false,
                error: { ...payload },
            };
        case actionTypes.SET_SELECTED_CAMPAIGN:
            return {
                ...state,
                ...payload,
            };
        case actionTypes.API_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaign: {
                    ...state.campaign,
                    status: "success",
                },
            };
        case actionTypes.API_CAMPAIGN_FAIL:
            return {
                ...state,
                ...payload,
            };
        case actionTypes.SET_DECISION:
            return {
                ...state,
                ...payload,
                showDecisionSplash: payload.decision.data,
            };
        case actionTypes.API_DECISION_SUCCESS:
            return {
                ...state,
                decision: {
                    ...state.decision,
                    status: "success",
                },
            };
        case actionTypes.SET_COMPLETED:
            return {
                ...state,
                completed: payload,
            };
        case actionTypes.SET_CANCELLATION_REASON:
            return {
                ...state,
                cancellation: payload,
            };
        case actionTypes.INIT_CONFIRMED_CUSTOMER_DETAILS:
            return {
                ...state,
                confirmedCustomerDetails: {
                    ...state.confirmedCustomerDetails,
                    data: {
                        firstname: state.customerAccount.customerFirstName,
                        lastname: state.customerAccount.customerLastName,
                        middlename: "",
                        email: state.customerAccount.email,
                        mobileNumber: state.customerAccount.mobile,
                        dateOfBirth: moment(
                            state.customerAccount.dateOfBirth
                        ).format("YYYY-MM-DD"),
                    },
                },
                confirmedPropertyDetails: {
                    ...state.confirmedPropertyDetails,
                    data: {
                        siteAddress: state.customerAccount.address,
                        mirn: "",
                        nmi: "",
                        postalAddress: "",
                    },
                },
            };

        case actionTypes.SET_SITE_POSTAL_SAME:
            return {
                ...state,
                confirmedPropertyDetails: {
                    data: {
                        ...state.confirmedPropertyDetails.data,
                        SiteAddressIsPostalAddress: payload,
                    },
                },
                isSiteAndPostalAddressSame: payload,
            };
        case actionTypes.SET_POSTAL_ADDRESS:
            return {
                ...state,
                confirmedPropertyDetails: {
                    data: {
                        ...state.confirmedPropertyDetails.data,
                        postalAddress: payload.AddressFull,
                        PostalApartmentNumber: payload.UnitNumber,
                        PostalApartmentType: payload.UnitType,
                        PostalBuildingName: payload.BuildingName,
                        PostalFloorNumber: payload.LevelNumber,
                        PostalFloorType: payload.LevelType,
                        PostalLocationDescriptor: null,
                        PostalLotNumber: null,
                        PostalPostCode: payload.Postcode,
                        PostalState: payload.State,
                        PostalStreetName: payload.Street,
                        PostalStreetNumber: payload.Number,
                        PostalStreetNumberSuffix: payload.NumberLast,
                        PostalStreetSuffix: payload.StreetSuffix,
                        PostalStreetType: payload.StreetType,
                        PostalSuburb: payload.Suburb,
                        streetLinePostal: payload.StreetLine,
                    },
                },
            };

        case actionTypes.SET_SITE_UNSTRUCTURED_ADDRESS:
            return {
                ...state,
                confirmedPropertyDetails: {
                    data: {
                        ...state.confirmedPropertyDetails.data,
                        siteAddress: payload.AddressFull,
                        AddressFull: payload.AddressFull,
                        SiteApartmentNumber: payload.UnitNumber,
                        SiteApartmentType: payload.UnitType,
                        SiteBuildingName: payload.BuildingName,
                        SiteFloorNumber: payload.LevelNumber,
                        SiteFloorType: payload.LevelType,
                        SiteLocationDescriptor: null,
                        SiteLotNumber: null,
                        SitePostCode: payload.Postcode,
                        SiteState: payload.State,
                        SiteStreetName: payload.Street,
                        SiteStreetNumber: payload.Number,
                        SiteStreetNumberSuffix: payload.NumberLast,
                        SiteStreetSuffix: payload.StreetSuffix,
                        SiteStreetType: payload.StreetType,
                        SiteSuburb: payload.Suburb,
                        streetLineSite: payload.StreetLine,
                    },
                },
            };
        case actionTypes.API_CONFIRMED_PROPERTY_DETAILS_SUCCESS:
            return {
                ...state,
                confirmedPropertyDetails: {
                    ...state.confirmedPropertyDetails,
                    status: "success",
                },
            };
        case actionTypes.ON_SELECT_COMMUNICATION_METHOD:
            return {
                ...state,
                confirmedPropertyDetails: {
                    data: {
                        ...state.confirmedPropertyDetails.data,
                        communicationMethod: payload,
                    },
                },
            };
        case actionTypes.SET_SUGGEST_SITE_ADDRESS:
            return {
                ...state,
                siteAddressSuggest: payload,
            };
        case actionTypes.SET_USE_SITE_ADDRESS_SUGGEST:
            return {
                ...state,
                ...payload,
            };
        case actionTypes.CLOSE_DECISION_SPLASH:
            return {
                ...state,
                showDecisionSplash: null,
            };

        case actionTypes.API_COMMODITY_TYPE_SUCCESS:
            return {
                ...state,
                commodityType: {
                    ...state.commodityType,
                    status: "success",
                },
            };
        case actionTypes.SET_CUSTOMER_TYPE:
            return {
                ...state,
                ...payload,
                tarrifOptions: [], // Reset Tarrif Options so we dont mix Business and Residential Customer type
            };
        case actionTypes.API_CUSTOMER_TYPE_SUCCESS:
            return {
                ...state,
                customerType: {
                    ...state.customerType,
                    status: "success",
                },
            };

        // case actionTypes.API_FUNNEL_DATA_SUCCESS:
        //     return {
        //         ...state,
        //         funnelData: { ...payload }
        //     };

        // case actionTypes.ON_SELECT_PRODUCT:
        //     return {
        //         ...state,
        //         productSelection: payload,
        //     };
        case actionTypes.API_TARRIF_SUCCESS:
            return {
                ...state,
                tarrifs: {
                    ...state.tarrifs,
                    ...payload.tarrifData,
                },
                tarrifOptions: payload.tarrifOptions,
            };
        case actionTypes.ON_CHANGE_METER_TYPE:
            return {
                ...state,
                meterType: payload,
            };
        case actionTypes.ON_TOGGLE_RATES:
            return {
                ...state,
                toggleRates: {
                    ...state.toggleRates,
                    ...payload,
                },
            };
        case "RESET_DEBIT":
            return {
                ...state,
                debit: {
                    isSignup: null,
                    paymentMethod: null,
                },
            };
        case actionTypes.SET_SIGNUP_TO_DEBIT:
            return {
                ...state,
                debit: {
                    ...state.debit,
                    isSignup: payload,
                },
            };
        case actionTypes.API_DEBIT_START:
            return {
                ...state,
                debit: {
                    ...state.debit,
                    status: "loading",
                },
            };

        case actionTypes.API_DEBIT_SUCCESS:
            return {
                ...state,
                debit: {
                    ...state.debit,
                    status: "success",
                },
            };
        case actionTypes.API_DEBIT_FAIL:
            return {
                ...state,
                debit: {
                    ...state.debit,
                    status: "failed",
                },
            };

        case actionTypes.ON_SELECT_DISTRIBUTOR:
            return {
                ...state,
                distributor: {
                    ...state.distributor,
                    selected: payload,
                },
            };

        case actionTypes.SET_FILTERED_PLANS:
            return {
                ...state,
                funnelData: {
                    ...state.funnelData,
                    filtered: payload,
                },
            };

        case actionTypes.API_DISTRIBUTORS_SUCCESS:
            return {
                ...state,
                distributor: {
                    ...state.distributor,
                    data: payload,
                },
            };

        case actionTypes.SET_LIFE_SUPPORT_REQUIRED:
            return {
                ...state,
                lifeSupport: {
                    ...state.lifeSupport,
                    isRequired: payload,
                },
            };
        case actionTypes.SET_LIFE_SUPPORT_TYPE:
            return {
                ...state,
                lifeSupport: {
                    ...state.lifeSupport,
                    type: {
                        ...state.lifeSupport.type,
                        ...payload,
                    },
                },
            };
        case actionTypes.RESET_LIFE_SUPPORT_TYPE:
            return {
                ...state,
                lifeSupport: {
                    ...state.lifeSupport,
                    type: {
                        electricity: null,
                        gas: null,
                    },
                },
            };
        case actionTypes.API_LIFE_SUPPORT_SUCCESS:
            return {
                ...state,
                lifeSupport: {
                    ...state.lifeSupport,
                    status: "success",
                },
            };
        case actionTypes.ON_SELECT_INTERACTION_TYPE:
            return {
                ...state,
                interaction: {
                    ...state.interaction,
                    type: payload,
                },
            };

        case actionTypes.API_INTERACTION_TYPE_START:
            return {
                ...state,
                interaction: {
                    ...state.interaction,
                    status: "loading",
                },
            };

        case actionTypes.API_INTERACTION_TYPE_SUCCESS:
            return {
                ...state,
                interaction: {
                    ...state.interaction,
                    status: "success",
                },
            };
        case actionTypes.ON_SUBMIT_CHURN_INFO:
            return {
                ...state,
                churnInfo: {
                    ...state.churnInfo,
                    data: {
                        ...payload,
                    },
                },
            };

        case actionTypes.API_CHURN_INFO_START:
            return {
                ...state,
                churnInfo: {
                    ...state.churnInfo,
                    status: "loading",
                },
            };

        case actionTypes.API_CHURN_INFO_SUCCESS:
            return {
                ...state,
                churnInfo: {
                    ...state.churnInfo,
                    status: "success",
                },
            };
        case actionTypes.API_CHURN_INFO_FAIL:
            return {
                ...state,
                churnInfo: {
                    ...state.churnInfo,
                    status: "failed",
                },
            };
        case actionTypes.SET_CALL_OUTCOME:
            return {
                ...state,
                callOutcome: {
                    ...state.callOutcome,
                    ...payload,
                },
            };
        case actionTypes.ON_SUBMIT_CALL_OUTCOME:
            return {
                ...state,
                callOutcome: {
                    ...state.callOutcome,
                    data: payload,
                },
            };
        case actionTypes.API_CALL_OUTCOME_START:
            return {
                ...state,
                callOutcome: {
                    ...state.callOutcome,
                    status: "loading",
                },
            };

        case actionTypes.API_CALL_OUTCOME_SUCCESS:
            return {
                ...state,
                callOutcome: {
                    ...state.callOutcome,
                    status: "success",
                },
            };
        case actionTypes.API_CALL_OUTCOME_FAIL:
            return {
                ...state,
                callOutcome: {
                    ...state.callOutcome,
                    status: "failed",
                },
            };
        case actionTypes.ON_SUBMIT_DETAILS:
            return {
                ...state,
                details: {
                    ...state.details,
                    data: {
                        ...state.details.data,
                        ...payload,
                    },
                },
            };

        case actionTypes.API_DETAILS_START:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: "loading",
                },
            };

        case actionTypes.API_DETAILS_SUCCESS:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: "success",
                },
            };
        case actionTypes.API_DETAILS_FAIL:
            return {
                ...state,
                details: {
                    ...state.details,
                    status: "failed",
                },
            };
        case actionTypes.SET_EIC:
            return {
                ...state,
                eicSubmission: {
                    ...payload,
                },
            };

        case actionTypes.API_MOVING_HOUSE_START:
            return {
                ...state,
                movingHouse: {
                    ...state.movingHouse,
                    status: "loading",
                },
            };
        case actionTypes.API_MOVING_HOUSE_SUCCESS:
            return {
                ...state,
                movingHouse: {
                    ...state.movingHouse,
                    status: "success",
                },
            };
        case actionTypes.SET_CONCESSION_REQUIRED:
            return {
                ...state,
                concessionCard: {
                    ...state.concessionCard,
                    isRequired: payload,
                },
            };
        case actionTypes.SET_CONCESSION_CARD:
            return {
                ...state,
                concessionCard: {
                    ...state.concessionCard,
                    data: {
                        ...payload,
                    },
                },
            };
        case actionTypes.RESET_CONCESSION_CARD:
            return {
                ...state,
                concessionCard: {
                    ...state.concessionCard,
                    data: {
                        type: null,
                        number: null,
                        expiryDate: null,
                    },
                },
            };
        case actionTypes.API_CONCESSION_REQUIRED_START:
            return {
                ...state,
                concessionCard: {
                    ...state.concessionCard,
                    status: "loading",
                },
            };

        case actionTypes.API_CONCESSION_REQUIRED_SUCCESS:
            return {
                ...state,
                concessionCard: {
                    ...state.concessionCard,
                    status: "success",
                },
            };
        case actionTypes.API_CONCESSION_REQUIRED_FAIL:
            return {
                ...state,
                concessionCard: {
                    ...state.concessionCard,
                    status: "failed",
                },
            };
        case actionTypes.SET_HEAR_US_IN_FUTURE:
            return {
                ...state,
                hearUsInFuture: payload,
            };
        case actionTypes.SET_AUTHENTICATION_ID_TYPE:
            return {
                ...state,
                authenticationId: {
                    ...state.authenticationId,
                    data: {
                        ...state.authenticationId.data,
                        type: payload,
                    },
                },
            };

        case actionTypes.API_AUTHENTICATION_ID_START:
            return {
                ...state,
                authenticationId: {
                    ...state.authenticationId,
                    status: "loading",
                },
            };

        // case actionTypes.API_AUTHENTICATION_ID_SUCCESS:
        //     return {
        //         ...state,
        //         authenticationId: {
        //             ...state.authenticationId,
        //             status: "success",
        //         },
        //     };
        // case actionTypes.API_AUTHENTICATION_ID_FAIL:
        //     return {
        //         ...state,
        //         authenticationId: {
        //             ...state.authenticationId,
        //             status: "failed",
        //         },
        //     };
        case actionTypes.SET_CURRENT_STEP:
            return {
                ...state,
                curStep: { ...payload.curStep },
                activeStep: payload.activeStep,
                stepDirection: payload.stepDirection,
            };

        case actionTypes.ON_SELECT_OFFERED_CREDITS:
            return {
                ...state,
                details: {
                    ...state.details,
                    data: {
                        ...state.details.data,
                        creditsOffered: payload,
                    },
                },
            };
        case actionTypes.SET_INITIAL_STEPS:
            return {
                ...state,
                steps: [...getInitialSteps()],
            };
        case actionTypes.SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: payload,
            };
        case actionTypes.SET_SHOW_RESET_WARNING:
            return {
                ...state,
                showResetWarning: payload,
            };
        case actionTypes.ON_CANCEL_RESET:
            return {
                ...state,
                showResetWarning: false,
            };
        case actionTypes.ON_SELECT_RETENTION_SUCCESS:
            return {
                ...state,
                isRetentionSuccess: payload,
            };
        case actionTypes.ON_PROCEED_RESET:
            return {
                ...getInitialAppState()
            };
        case actionTypes.ON_SUBMIT_ADDRESS:
            return {
                ...state,
                customerAddress: {
                    searchMode: state.customerAddress.searchMode,
                    unstructured: {
                        unit: payload.UnitNumber,
                        streetNo: payload.Number,
                        streetName: payload.Street,
                        streetType: payload.StreetType,
                        lotNo: payload.lotNo,
                        suburb: payload.Suburb,
                        state: payload.State,
                        postcode: payload.Postcode,
                    },
                    data: {},   //API Response
                    status: null,   //API Status: start, success, failed
                    input: state.customerAddress.input
                }
            };
        case actionTypes.RESET_STEPS:
            return {
                ...state,
                ...getInitialAppState(),
            };
        case actionTypes.TOGGGLE_OLDMAN:
            return {
                ...state,
                confirmedCustomerDetails: {
                    ...state.confirmedCustomerDetails,
                    showOldMan: payload
                }
                
            };
        case actionTypes.API_HANDLED_CALLBACK:
            let handler = {};
            handler[payload.name] = payload.value;

            return {
                ...state,
                apiCallback: {
                    ...state.apiCallback,
                    ...handler,
                },
            };
        default:
            return state;
    }
};

export default appReducer;
