import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
axios.defaults.baseURL = API_BASE_URL;

// const SVaxios = axios.create({
//     baseURL: process.env.REACT_APP_SV_API_BASE_URL,
// });
const tokenFromLocal = localStorage.getItem('access_token');
if(tokenFromLocal) {
    // SVaxios.defaults.headers.common['Authorization'] = `Bearer ${tokenFromLocal}`;
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenFromLocal}`;
    axios.defaults.headers.common['Content-Type'] = `application/json`;
}

export const setAuthToken = token => {
    if (token) {
        //applying token
        // SVaxios.defaults.headers.common['Authorization'] = token;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        //deleting the token from header
        // delete SVaxios.defaults.headers.common['Authorization'];
        delete axios.defaults.headers.common['Authorization'];
    }
}

export const get = (url, config) => axios.get(url, config);

export const put = (url, config) => axios.put(url, config);

export const post = (url, payload) => axios.post(url, payload);

// export const signIn = (payload) => SVaxios.post('/api/auth/login', payload);
