import * as actionTypes from "../action-types";

const initialState = {
    data: null,
    error: {},
    loading: false,
};

const dashboardReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        
        default:
            return state;
    }
};

export default dashboardReducer;
