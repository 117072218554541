import { LOADING_FAIL, LOADING_SUCCESS, LOADING_START, ON_ERROR, DISMISS_ERROR } from "../action-types";

const initialState = {
  loading: null,
  error: null
}

const uiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_START:
      return {
          ...state,
          loading: true
      };
    case LOADING_SUCCESS:
      return {
          ...state,
          loading: false
      };
    case LOADING_FAIL:
      return {
          ...state,
          loading: false,
          error: { ...payload }
      };
    case ON_ERROR:
      return {
          ...state,
          error: { ...payload.error }
      };
    case DISMISS_ERROR:
      return {
          ...state,
          error: null
      };
    
      default:
      return state
  }
}

export default uiReducer