import { orderBy } from 'lodash'

export const getInitialAppState = () => {
    return {
        quoteNumber: null,
        quoteReference: null,
        steps: getInitialSteps(),
        curStep: {
            name: 'enter-address',
            caption: "Select Address*",
            completeWithNext: true
        },
        activeStep: 0,
        stepDirection: 'left',
        customerAddress: {
            input: '',  // UNIT 7 56 SMITH ST, SOUTH MELBOURNE VIC
            searchMode: 'auto',
            unstructured: {
                unit: '',
                streetNo: '',
                streetName: '',
                streetType: '',
                lotNo: '',
                suburb: '',
                postcode: '',
            },
            agreeToPolicy: false,
            data: {},   //API Response
            status: null,   //API Status: start, success, failed
        },
        accountDetails: {
            input: {},  //FOrm input
            data: {},   //API Response
            status: null,   //API Status: start, success, failed
        },
        plan: {
            input: null,
            status: null,
        },
        serviceInfo: {
            input: {
                existingCommodityTypes: {
                    electricity: null,
                    gas: null,
                    internet: null
                },
                electricityAccountNumberRequired: false,
                gasAccountNumberRequired: false,
                internetAccountNumberRequired: false
            },
            status: null,
        },
        serviceAddons: {
            input: {},
            status: null,
        },
        confirmedCustomerDetails: {
            showOldMan: false,
            input: {},
            status: null,
        },
        confirmedAddressDetails: {
            input: {
                siteAddress: '',
                postalAddress: '',
                siteSameAsPostal: true,
                movingHouse: null,
                proposedMovingDate: null
            },
            status: null,
        },
        authenticationId: {
            input: {
                authenticationType: ''
            },
            status: null,
        },
        consentAgreement: {
            input: {
                showSignupFeedback: null,
                doneReadingTNC: false
            },
            status: null,
        },
        leadTime: {},
        summaryPayment: {
            input: {},
            status: null,
        },
        splashWait: {
            show: null,
            desc: null,
            descDefault: 'Stay hydrated, grab a glass of water... We are doing some ground work to fetch a perfect plan for you!'
        },
        resume: {
            status: null
        },

//============================================
        campaign: {
            data: null,
            status: null,
        },
        decision: {
            data: null,
            status: null,
        },
        commodityType: {
            data: {
                type: null,
                electricity: false,
                gas: false,
            },
            status: null
        },
        showDecisionSplash: null,
        showResetWarning: null,
        customerType: {
            data: null,
            status: null,
        },
        cancellation: {
            cancellationReason: '',
            churnTo: ''
        },
        completed: [],
        isSiteAndPostalAddressSame: null,
        siteAddressSuggest: {},
        isUseSiteAddressSuggest: null,
        lifeSupport: {
            isRequired: null,
            type: {
                electricity: undefined,
                gas: undefined
            },
        },
        eicSubmission: {
            clear: false,
            concession: false,
            life_support: false,
            price: false,
            next_price: false,
            vdo_dmo: false,
            eic: false
        },
        concessionCard: {
            isRequired: null,
            status: null,
            data: {
                type: null,
                number: null,
                expiryDate: null
            }
        },
        movingHouse: {
            isMoving: null,
            moveInDate: null,
            status: null
        },
        isRetentionSuccess: null,
        // authenticationId: {
        //     data: {
        //         type: '',
        //         country: null,
        //         expiryDate: null,
        //         name: null,
        //         number: null,
        //         state: null
        //     }
        // },
        hearUsInFuture: null,
        debit: {
            isSignup: null,
            paymentMethod: null,
        },
        showSignupFeedback: true,
        customerAccount: {
            accountNumber: 1216716,
            found: null
        },
        error: {},
        loading: false,
        loadingMsg: null,
        funnelData: {
            filtered: getStaticPlans()
        },
        tarrifs: {},
        meterType: 'SINGLE',
        toggleRates: {
            elec: false,
            gas: false
        },
        apiCallback: {
            campaign: null
        },  //Handler for All API callback
        nmiMirnDiscoverySuccess: null,
        interaction: {
            type: null,
            status: null
        },
        callOutcome: {
            data: {},
            showComment: false,
            status: null,
        },
        details: {
            data: {
                creditsOffered: null,
            },
            status: null,
        },
        churnInfo: {
            data: {},
            status: null
        },
        distributor: {
            selected: null,
            data: [],
            status: null
        }
    };
}

const getStaticPlans = () => {
    return [
        {
            name: 'nbn25'
        }, 
        {
            name: 'nbn50'
        }, 
        {
            name: 'nbn100'
        }
    ]
}

export const getInitialSteps = () => {
    return [
        {
            name: 'enter-address',
            caption: "Select Address *",
            completeWithNext: true
        },
        {
            name: 'account-details',
            caption: "Account Details *",
            completeWithNext: true
        },
        {
            name: 'service-information',
            caption: "Service Information *",
            completeWithNext: true
        },
        {
            name: 'product-selection',
            caption: "Product Selection *",
            completeWithNext: true
        },
        {
            name: 'service-addons',
            caption: "Service Addons *",
            completeWithNext: true
        },
        {
            name: 'customer-details',
            caption: "Customer Details *",
            completeWithNext: true
        },
        {
            name: 'address-details',
            caption: "Address Details *",
            completeWithNext: true
        },
        {
            name: 'authentication-id',
            caption: "Authentication Details *",
            completeWithNext: true
        },
        {
            name: 'summary-payment',
            caption: "Summary and Payment *",
            completeWithNext: true
        },
        {
            name: 'consents-agreement',
            caption: "Consents and Agreement *",
            completeWithNext: true
        },
    ];
}

export const toBoolean = (str) => {
    return str === 'yes' ? true : (str === 'no' ? false : null)
}

export const toYesNo = (value) => {
    return value === true ? 'yes' : (value === false ? 'no' : null)
}